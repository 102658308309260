import React, {useState, useEffect} from 'react';
import {withTranslation} from 'react-i18next';
import {Form, Field} from 'react-final-form';
import {classNames} from 'primereact/utils';
import {InputText} from 'primereact/inputtext';
import {Button} from 'primereact/button';
import {Panel} from "primereact/panel";
import {Dropdown} from "primereact/dropdown";
import {InputSwitch} from "primereact/inputswitch";

import PartnerFormService from '../../services/partner/PartnerFormService'
import CountryListService from "../../services/country/CountryListService";
import PartnercontactFormService from "../../services/partnercontact/PartnercontactFormService";
import PartnercontactListService from "../../services/partnercontact/PartnercontactListService";
import {DataTable} from "primereact/datatable";
import {Column} from "primereact/column";
import {Dialog} from "primereact/dialog";
import PartnerShowService from "../../services/partner/PartnerShowService";
import PartnerContactsComponent from "./PartnerContactsComponent";
import PartnerAddressesComponent from "./PartnerAddressesComponent";

const PartnerFormComponent = (props) => {

  const [formData, setFormData] = useState({
    addressCountryCode: "HU",
    type: "organisation",
    active: true,
    customer: true,
    vendor: true,
  });
  const [loading, setLoading] = useState(false);
  const [countries, setCountries] = useState([])

  const validate = (data) => {
    let errors = {};
    // if (!data.name) {
    //   errors.name = 'Az megnevezés nem lehet üres';
    // }
    // if (!data.type) {
    //   errors.type = 'A típus nem lehet üres';
    // }
    // if (!data.addressCountryCode) {
    //   errors.addressCountryCode = 'Az ország nem lehet üres';
    // }
    // if (!data.addressPostalCode) {
    //   errors.addressPostalCode = 'Az IRSZ nem lehet üres';
    // }
    // if (!data.addressCity) {
    //   errors.addressCity = 'A település nem lehet üres';
    // }
    // if (!data.addressDetail) {
    //   errors.addressDetail = 'A cím nem lehet üres';
    // }
    // if (data.type === 'organisation') {
    //   if (!data.vatNumber) {
    //     errors.vatNumber = 'Az adószám nem lehet üres';
    //   }
    // }
    return errors;
  };
  const onSubmit = (data, form) => {
    setLoading(true)
    setFormData({
      ...formData,
      data
    });
    if (props && props.initialValues || formData.id) {
      PartnerFormService(data, 'put')
        .then(response => {
          window.App.toastShow('success', 'Sikeres módosítás!');
          window.App.setState({
            rerenderPartnerList: true,
            sidebarPartnerUpdate: false,
            sidebarPartnerCreate: false,
          })
          setLoading(false)
        })
        .catch(error => {
          window.App.toastShow('error', 'Hiba történt!', error.toString(), '', error.response);
          setLoading(false)
        })
    } else {
      PartnerFormService(data, 'post')
        .then(response => {
          setFormData({
            ...formData,
            id: response.data.id
          })
          window.App.toastShow('success', 'Sikeres mentés!');
          window.App.setState({
            newPartnerId: response.data.id,
            newPartnerName: response.data.name
          })
          setLoading(false)
          window.App.setState({rerenderPartnerList: true})
          if (props.closeForm === true) {
            window.App.setState({
              sidebarPartnerCreate: false,
              sidebarPartnerUpdate: false,
            })
          }
          setLoading(false)
        })
        .catch(error => {
          window.App.toastShow('error', 'Hiba történt!', error.toString(), '', error.response);
          setLoading(false)
        })
    }
    form.restart();
  };
  const isFormFieldValid = (meta) => !!(meta.touched && meta.error);
  const getFormErrorMessage = (meta) => {
    return isFormFieldValid(meta) && <small className="p-error">{meta.error}</small>;
  };

  useEffect(() => {
    CountryListService(JSON.stringify({
      first: 0,
      rows: 9999,
      page: 0,
    }))
      .then(response => {
        setCountries(response.data)
      })
      .catch(error => {
        window.App.toastShow('error', 'Hiba történt!', error.toString(), '', error.response);
      })
    if (props && props.initialValues) {
      setLoading(true)
      PartnerShowService(props.initialValues.id)
        .then(response => {
          setFormData(response.data)
          setLoading(false)
        })
        .catch(error => {
          setLoading(false)
          window.App.toastShow('error', 'Hiba történt!', error.toString(), '', error.response);
        })
    }
  }, [])

  return (
    <Form onSubmit={onSubmit} initialValues={formData}
          validate={validate} render={({handleSubmit}) => (
      <form onSubmit={handleSubmit} className="">
        <Panel headerTemplate={
          <div className={"p-grid"}>
            <div className={"p-col-12"}>
              <h3>
                <i className={"pi pi-list"}></i> Alapadatok
              </h3>
            </div>
          </div>
        } className={""}>
          <div className={"p-grid"}>
            <div className={"p-col-12 p-md-9 p-lg-9"}>
              <Field name="name" render={({input, meta}) => (
                <div className="p-field p-fluid">
                  <label htmlFor="name"
                         className={classNames({'p-error': isFormFieldValid(meta)})}>Név</label>
                  <span className="p-d-block">
                      <InputText id="name" {...input}
                                 onChange={(e) => {
                                   setFormData({
                                     ...formData,
                                     name: e.target.value
                                   })
                                 }}
                                 value={formData.name}
                                 className={classNames({'p-invalid': isFormFieldValid(meta)})}/>
                    </span>
                  {getFormErrorMessage(meta)}
                </div>
              )}/>
            </div>
            <div className={"p-col-12 p-md-3 p-lg-3"}>
              <Field name="type" render={({input, meta}) => (
                <div className="p-field p-fluid">
                  <label htmlFor="type"
                         className={classNames({'p-error': isFormFieldValid(meta)})}>Típus</label>
                  <span className="p-d-block">
                    <Dropdown options={[
                      {
                        name: "Személy",
                        id: "person"
                      },
                      {
                        name: "Cég",
                        id: "organisation"
                      },
                    ]}
                              optionLabel="name"
                              optionValue="id"
                              id={"type"}
                              emptyMessage={"Még nincs típus..."}
                              onChange={(e) => {
                                setFormData({
                                  ...formData,
                                  type: e.value
                                })
                              }}
                              value={formData.type}
                              showClear
                    />
                  </span>
                  {getFormErrorMessage(meta)}
                </div>
              )}/>
            </div>
          </div>
          <div className={"p-grid p-mt-1"}>
            <div className={"p-col-12"}>
              <h3>
                {formData && formData.type === 'person' &&
                <>
                  <i className={"pi pi-map-marker"}></i> Lakcím
                </>
                }
                {formData && formData.type === 'organisation' &&
                <>
                  <i className={"pi pi-map-marker"}></i> Székhely cím
                </>
                }
              </h3>
            </div>
          </div>
          <div className={"p-grid"}>
            <div className={"p-col-12 p-md-3 p-lg-3"}>
              <Field name="addressCountryCode" render={({input, meta}) => (
                <div className="p-field p-fluid">
                  <label htmlFor="addressCountryCode"
                         className={classNames({'p-error': isFormFieldValid(meta)})}>Ország</label>
                  <span className="p-d-block">
                      <Dropdown options={countries}
                                optionLabel="name"
                                optionValue="code"
                                id={"addressCountryCode"}
                                emptyMessage={"Még nincs ország..."}
                                onChange={(e) => {
                                  setFormData({
                                    ...formData,
                                    addressCountryCode: e.value
                                  })
                                }}
                                value={formData.addressCountryCode}
                                showClear
                      />
                    </span>
                  {getFormErrorMessage(meta)}
                </div>
              )}/>
            </div>
            <div className={"p-col-12 p-md-2 p-lg-2"}>
              <Field name="addressPostalCode" render={({input, meta}) => (
                <div className="p-field p-fluid">
                  <label htmlFor="addressPostalCode"
                         className={classNames({'p-error': isFormFieldValid(meta)})}>IRSZ</label>
                  <span className="p-d-block">
                      <InputText id="addressPostalCode" {...input}
                                 onChange={(e) => {
                                   setFormData({
                                     ...formData,
                                     addressPostalCode: e.target.value
                                   })
                                 }}
                                 value={formData.addressPostalCode}
                                 className={classNames({'p-invalid': isFormFieldValid(meta)})}/>
                    </span>
                  {getFormErrorMessage(meta)}
                </div>
              )}/>
            </div>
            <div className={"p-col-12 p-md-3 p-lg-3"}>
              <Field name="addressCity" render={({input, meta}) => (
                <div className="p-field p-fluid">
                  <label htmlFor="addressCity"
                         className={classNames({'p-error': isFormFieldValid(meta)})}>Település</label>
                  <span className="p-d-block">
                      <InputText id="addressCity" {...input}
                                 onChange={(e) => {
                                   setFormData({
                                     ...formData,
                                     addressCity: e.target.value
                                   })
                                 }}
                                 value={formData.addressCity}
                                 className={classNames({'p-invalid': isFormFieldValid(meta)})}/>
                    </span>
                  {getFormErrorMessage(meta)}
                </div>
              )}/>
            </div>
            <div className={"p-col-12 p-md-4 p-lg-4"}>
              <Field name="addressDetail" render={({input, meta}) => (
                <div className="p-field p-fluid">
                  <label htmlFor="addressDetail"
                         className={classNames({'p-error': isFormFieldValid(meta)})}>Cím</label>
                  <span className="p-d-block">
                      <InputText id="addressCity" {...input}
                                 onChange={(e) => {
                                   setFormData({
                                     ...formData,
                                     addressDetail: e.target.value
                                   })
                                 }}
                                 value={formData.addressDetail}
                                 className={classNames({'p-invalid': isFormFieldValid(meta)})}/>
                    </span>
                  {getFormErrorMessage(meta)}
                </div>
              )}/>
            </div>
          </div>
          {formData.type === 'organisation' &&
          <>
            <div className={"p-grid p-mt-1"}>
              <div className={"p-col-12"}>
                <h3>
                  <i className={"pi pi-file"}></i> Azonosítók
                </h3>
              </div>
            </div>
            <div className={"p-grid"}>
              <div className={"p-col-12 p-md-4 p-lg-4"}>
                <Field name="vatNumber" render={({input, meta}) => (
                  <div className="p-field p-fluid">
                    <label htmlFor="vatNumber"
                           className={classNames({'p-error': isFormFieldValid(meta)})}>Adószám</label>
                    <span className="p-d-block">
                      <InputText id="vatNumber" {...input}
                                 onChange={(e) => {
                                   setFormData({
                                     ...formData,
                                     vatNumber: e.target.value
                                   })
                                 }}
                                 value={formData.vatNumber}
                                 className={classNames({'p-invalid': isFormFieldValid(meta)})}/>
                    </span>
                    {getFormErrorMessage(meta)}
                  </div>
                )}/>
              </div>
              <div className={"p-col-12 p-md-4 p-lg-4"}>
                <Field name="communityVatNumber" render={({input, meta}) => (
                  <div className="p-field p-fluid">
                    <label htmlFor="communityVatNumber"
                           className={classNames({'p-error': isFormFieldValid(meta)})}>Közösségi adószám</label>
                    <span className="p-d-block">
                      <InputText id="communityVatNumber" {...input}
                                 onChange={(e) => {
                                   setFormData({
                                     ...formData,
                                     communityVatNumber: e.target.value
                                   })
                                 }}
                                 value={formData.communityVatNumber}
                                 className={classNames({'p-invalid': isFormFieldValid(meta)})}/>
                    </span>
                    {getFormErrorMessage(meta)}
                  </div>
                )}/>
              </div>
              <div className={"p-col-12 p-md-4 p-lg-4"}>
                <Field name="other" render={({input, meta}) => (
                  <div className="p-field p-fluid">
                    <label htmlFor="other"
                           className={classNames({'p-error': isFormFieldValid(meta)})}>Egyéb jelzés</label>
                    <span className={"p-d-block"}>
                      <InputText id="other" {...input}
                                 onChange={(e) => {
                                   setFormData({
                                     ...formData,
                                     other: e.target.value
                                   })
                                 }}
                                 value={formData.other}
                                 className={classNames({'p-invalid': isFormFieldValid(meta)})}/>
                    </span>
                    {getFormErrorMessage(meta)}
                  </div>
                )}/>
              </div>
            </div>
          </>
          }
          <div className={"p-grid p-mt-1"}>
            <div className={"p-col-12"}>
              <h3>
                <i className={"pi pi-phone"}></i> Központi kapcsolat
              </h3>
            </div>
          </div>
          <div className={"p-grid"}>
            <div className={"p-col-12 p-lg-6"}>
              <Field name="phone" render={({input, meta}) => (
                <div className="p-field p-fluid">
                  <label htmlFor="phone"
                         className={classNames({'p-error': isFormFieldValid(meta)})}>Telefon</label>
                  <span className="p-d-block">
                      <InputText id="phone" {...input}
                                 onChange={(e) => {
                                   setFormData({
                                     ...formData,
                                     phone: e.target.value
                                   })
                                 }}
                                 value={formData.phone}
                                 className={classNames({'p-invalid': isFormFieldValid(meta)})}/>
                    </span>
                  {getFormErrorMessage(meta)}
                </div>
              )}/>
            </div>
            <div className={"p-col-12 p-lg-6"}>
              <Field name="email" render={({input, meta}) => (
                <div className="p-field p-fluid">
                  <label htmlFor="email"
                         className={classNames({'p-error': isFormFieldValid(meta)})}>Email</label>
                  <span className="p-d-block">
                      <InputText id="email" {...input}
                                 onChange={(e) => {
                                   setFormData({
                                     ...formData,
                                     email: e.target.value
                                   })
                                 }}
                                 value={formData.email}
                                 className={classNames({'p-invalid': isFormFieldValid(meta)})}/>
                    </span>
                  {getFormErrorMessage(meta)}
                </div>
              )}/>
            </div>
            <div className={"p-col-12 p-lg-12"}>
              <Field name="note" render={({input, meta}) => (
                <div className="p-field p-fluid">
                  <label htmlFor="note"
                         className={classNames({'p-error': isFormFieldValid(meta)})}>Megjegyzés</label>
                  <span className="p-d-block">
                      <InputText id="note" {...input}
                                 onChange={(e) => {
                                   setFormData({
                                     ...formData,
                                     note: e.target.value
                                   })
                                 }}
                                 value={formData.note}
                                 className={classNames({'p-invalid': isFormFieldValid(meta)})}/>
                    </span>
                  {getFormErrorMessage(meta)}
                </div>
              )}/>
            </div>
          </div>
          <div className={"p-grid p-mt-1"}>
            <div className={"p-col-12"}>
              <h3>
                <i className={"pi pi-info-circle"}></i> Tulajdonságok
              </h3>
            </div>
          </div>
          <div className={"p-grid"}>
            <div className={"p-col-12 p-md-4 p-lg-4"}>
              <Field name="active" render={({input, meta}) => (
                <div className="p-field p-fluid">
                  <label htmlFor="active"
                         className={classNames({'p-error': isFormFieldValid(meta)})}>Aktív</label>
                  <span className="p-d-block">
                    <InputSwitch
                      className={"p-mt-1"}
                      checked={formData.active}
                      value={formData.active}
                      onChange={e => setFormData({...formData, active: e.target.value})}/>
                  </span>
                  {getFormErrorMessage(meta)}
                </div>
              )}/>
            </div>
            <div className={"p-col-12 p-md-4 p-lg-4"}>
              <Field name="customer" render={({input, meta}) => (
                <div className="p-field p-fluid">
                  <label htmlFor="customer"
                         className={classNames({'p-error': isFormFieldValid(meta)})}>Vevő</label>
                  <span className="p-d-block">
                    <InputSwitch
                      className={"p-mt-1"}
                      checked={formData.customer}
                      value={formData.customer}
                      onChange={e => setFormData({...formData, customer: e.target.value})}/>
                  </span>
                  {getFormErrorMessage(meta)}
                </div>
              )}/>
            </div>
            <div className={"p-col-12 p-md-4 p-lg-4"}>
              <Field name="vendor" render={({input, meta}) => (
                <div className="p-field p-fluid">
                  <label htmlFor="vendor"
                         className={classNames({'p-error': isFormFieldValid(meta)})}>Szállító</label>
                  <span className="p-d-block">
                    <InputSwitch
                      className={"p-mt-1"}
                      checked={formData.vendor}
                      value={formData.vendor}
                      onChange={e => setFormData({...formData, vendor: e.target.value})}/>
                  </span>
                  {getFormErrorMessage(meta)}
                </div>
              )}/>
            </div>
          </div>
          <div className={"p-grid p-mt-3"}>
            <div className={"p-col-12 p-lg-6 p-xl-6"}>

            </div>
            <div className={"p-col-12 p-lg-6 p-xl-6 p-text-right"}>
              <Button type="submit" label="Mentés és bezár" icon={"pi pi-check"} loading={loading}
                      onClick={() => {
                        props = {...props, closeForm: true}
                      }}
                      className="p-button-success"/>
              <Button type="submit" label="Mentés" icon={"pi pi-check"} loading={loading}
                      onClick={() => {
                        props = {...props, closeForm: false}
                      }}
                      className="p-button-success p-ml-2"/>
            </div>
          </div>
        </Panel>
        {formData && formData.id && localStorage.getItem('roles').toString().includes('role_partner_contact_read') &&
        <>
          <Panel headerTemplate={
            <div className={"p-grid"}>
              <div className={"p-col-12"}>
                <h3>
                  <i className={"pi pi-list"}></i> Kapcsolattartók
                </h3>
              </div>
            </div>
          } className={"p-mt-3"}>
            <PartnerContactsComponent initialValues={formData}/>
          </Panel>
        </>
        }
        {formData && formData.id && localStorage.getItem('roles').toString().includes('role_partner_address_read') &&
        <>
          <Panel headerTemplate={
            <div className={"p-grid"}>
              <div className={"p-col-12"}>
                <h3>
                  <i className={"pi pi-list"}></i> Címek
                </h3>
              </div>
            </div>
          } className={"p-mt-3"}>
            <PartnerAddressesComponent initialValues={formData}/>
          </Panel>
        </>
        }
      </form>
    )}/>
  )
}
export default withTranslation('common')(PartnerFormComponent);
