import React, {useState, useEffect, useRef} from 'react';
import {withTranslation} from 'react-i18next';
import {Card} from "primereact/card";
import {Button} from "primereact/button";
import {Chart} from 'primereact/chart';
import {Sidebar} from "primereact/sidebar";
import {Link} from "react-router-dom";
import {TabView, TabPanel} from 'primereact/tabview';
import download from "js-file-download";
import moment from "moment";

import WorktypeFormComponent from "../../components/worktype/WorktypeFormComponent";
import WorktypeListComponent from "../../components/worktype/WorktypeListComponent";
import WorktypeShowComponent from "../../components/worktype/WorktypeShowComponent";
import SafetyFormComponent from "../../components/safety/SafetyFormComponent";
import SafetyListComponent from "../../components/safety/SafetyListComponent";
import SafetyeducationFormComponent from "../../components/safetyeducation/SafetyeducationFormComponent";
import SafetyeducationListComponent from "../../components/safetyeducation/SafetyeducationListComponent";
import SafetyeducationResultsComponent from "../../components/safetyeducation/SafetyeducationResultsComponent";
import SafetyeducationParticipantsComponent
  from "../../components/safetyeducation/SafetyeducationParticipantsComponent";
import SafetyDownloadReportService from "../../services/safety/SafetyDownloadReportService";

export const SafetyDashboardPage = () => {

  useEffect(() => {
    window.App.pageHeight()
    document.title = 'Munkavédelem' + ' - ' + process.env.REACT_APP_TITLE;
    setTimeout(function () {
      if (document.getElementsByClassName('activeMenu')[0]) {
        document.getElementsByClassName('activeMenu')[0].classList.remove("activeMenu")
      }
      if (document.getElementsByClassName('safety')[0]) {
        document.getElementsByClassName('safety')[0].classList.add("activeMenu")
      }
    }, 1000)
    if (
      document.getElementById('tabScroll')
      && document.getElementById('tabScroll').children
      && document.getElementById('tabScroll').children[0]
      && document.getElementById('tabScroll').children[0].querySelector("li:not(.p-disabled)").children[0]
    ) {
      document.getElementById('tabScroll').children[0].querySelector("li:not(.p-disabled)").children[0].click()
    }
  }, []);

  return (
    <div className="ModuleDashboardPage DashboardPage p-component p-p-3 ">
      {localStorage.getItem('module_occupational_safety') ? (
        <>
          <div className={"p-grid p-d-flex p-flex-wrap"}>
            <div className={"p-col-12 p-lg-8 p-xl-8 p-p-0 left p-position-relative"}>
              <TabView renderActiveOnly="true" className={"nav"} scrollable={window.App.state.tabScroll} id="tabScroll">
                {localStorage.getItem('roles').toString().includes('role_occ_safety_read') ? (
                  <TabPanel header="Munkavédelmi előírások">
                    <div className={"p-p-5"}>
                      <SafetyListComponent view="simple"/>
                    </div>
                  </TabPanel>
                ) : (
                  <TabPanel disabled></TabPanel>
                )}
                {localStorage.getItem('roles').toString().includes('role_occ_safety_edu_read') ? (
                  <TabPanel header="Munkavédelmi oktatások">
                    <div className={"p-p-5"}>
                      <SafetyeducationListComponent view="simple"/>
                    </div>
                  </TabPanel>
                ) : (
                  <TabPanel disabled></TabPanel>
                )}
                {localStorage.getItem('roles').toString().includes('role_work_type_read') && process.env.REACT_APP_BRAND !== 'entryfish' ? (
                  <TabPanel header="Munkatípusok">
                    <div className={"p-p-5"}>
                      <WorktypeListComponent view="simple"/>
                    </div>
                  </TabPanel>
                ) : (
                  <TabPanel disabled></TabPanel>
                )}
              </TabView>
            </div>
            <div className={"p-col-12 p-lg-4 p-xl-4 p-d-flex p-flex-wrap right p-p-5 p-text-light"}>
              <div className={"p-p-5 w-100"}>
                <h2 className={"p-mt-0"}>Műveletek</h2>
                <div className={"p-grid"}>
                  {localStorage.getItem('roles').toString().includes('role_occ_safety_write') &&
                  <div className={"p-col-12 p-lg-6 p-xl-4 p-d-flex"}>
                    <a onClick={() => window.App.setState({sidebarSafetyCreate: true})} className={"w-100"}>
                      <Card className={"p-text-center w-100 shadow-none"}>
                        <i className="pi pi-plus-circle bg-white p-d-block p-mb-2"
                           style={{'fontSize': '2em'}}></i>
                        Új munkavédelmi előírás
                      </Card>
                    </a>
                  </div>
                  }
                  {localStorage.getItem('roles').toString().includes('role_occ_safety_edu_write') &&
                  <div className={"p-col-12 p-lg-6 p-xl-4 p-d-flex"}>
                    <a onClick={() => window.App.setState({sidebarSafetyeducationCreate: true})} className={"w-100"}>
                      <Card className={"p-text-center w-100 shadow-none"}>
                        <i className="pi pi-plus-circle bg-white p-d-block p-mb-2"
                           style={{'fontSize': '2em'}}></i>
                        Új munkavédelmi oktatás
                      </Card>
                    </a>
                  </div>
                  }
                  {localStorage.getItem('roles').toString().includes('role_work_type_create') && process.env.REACT_APP_BRAND !== 'entryfish' &&
                  <div className={"p-col-12 p-lg-6 p-xl-4 p-d-flex"}>
                    <a onClick={() => window.App.setState({sidebarWorktypeCreate: true})} className={"w-100"}>
                      <Card className={"p-text-center w-100 shadow-none"}>
                        <i className="pi pi-plus-circle bg-white p-d-block p-mb-2"
                           style={{'fontSize': '2em'}}></i>
                        Új munkatípus
                      </Card>
                    </a>
                  </div>
                  }
                </div>
                {/*<Chart type="bar"*/}
                {/*       options={{*/}
                {/*         animation: {*/}
                {/*           duration: 0*/}
                {/*         },*/}
                {/*         plugins: {*/}
                {/*           legend: {*/}
                {/*             labels: {*/}
                {/*               color: '#FFF'*/}
                {/*             }*/}
                {/*           }*/}
                {/*         },*/}
                {/*         scales: {*/}
                {/*           x: {*/}
                {/*             ticks: {*/}
                {/*               color: '#FFF'*/}
                {/*             },*/}
                {/*             grid: {*/}
                {/*               color: '#FFF'*/}
                {/*             }*/}
                {/*           },*/}
                {/*           y: {*/}
                {/*             ticks: {*/}
                {/*               color: '#FFF'*/}
                {/*             },*/}
                {/*             grid: {*/}
                {/*               color: '#FFF'*/}
                {/*             }*/}
                {/*           },*/}
                {/*         }*/}
                {/*       }}*/}
                {/*       data={{*/}
                {/*         labels: ['Belépett', 'Kilépett', 'Bent tartózkodik', 'Visszautasított'],*/}
                {/*         datasets: [*/}
                {/*           {*/}
                {/*             label: 'Összes azonosítás: 134',*/}
                {/*             data: [30, 50, 32, 12],*/}
                {/*             backgroundColor: [*/}
                {/*               "#42A5F5",*/}
                {/*               "#66BB6A",*/}
                {/*               "#FFA726",*/}
                {/*               "#F8f8f8"*/}
                {/*             ]*/}
                {/*           }*/}
                {/*         ]*/}
                {/*       }}/>*/}
                {/*<h3 className={"p-text-center p-text-normal"}>Riport</h3>*/}
                <div className={"p-text-center p-pt-5"}>
                  {localStorage.getItem('roles').toString().includes('role_occ_safety_download')
                  && process.env.REACT_APP_BRAND !== 'entryfish' &&
                  <Button className="p-button-sm p-button-secondary p-button-highlighted p-ml-2"
                          value={"Projekt kimutatás letöltése"}
                          type="button"
                          label={"Munkavédelmi bejegyzések kimutatás"}
                          icon={"pi pi-download"}
                          loading={window.App.state.loadingSafetyDownloadReport}
                          onClick={() => {
                            window.App.setState({loadingSafetyDownloadReport: true})
                            SafetyDownloadReportService()
                              .then(response => {
                                download(response.data, localStorage.getItem('networkName')
                                  + '_'
                                  + 'munkaugyi-bejegyzesek-kimutatas'
                                  + '_'
                                  + moment.utc(new Date()).local().format('YYYY-MM-DD HH:mm:ss')
                                  + '.xlsx')
                                window.App.setState({loadingSafetyDownloadReport: false})
                              })
                              .catch(error => {
                                window.App.toastShow('error', 'Hiba történt!', error.toString(), '', error.response);
                                window.App.setState({loadingSafetyDownloadReport: false})
                              })
                          }}/>
                  }
                </div>
              </div>
              <div className={"p-text-center p-as-end w-100"}>
                <span className={"p-text-light"}
                      dangerouslySetInnerHTML={{__html: process.env.REACT_APP_COPYRIGHT.replace('[[YEAR]]', new Date().getFullYear())}}></span>
              </div>
            </div>
          </div>
          <Sidebar position="left" visible={window.App.state.sidebarSafetyCreate} closeOnEscape={true}
                   style={{width: '98%'}}
                   onHide={() => {
                     window.App.setState({sidebarSafetyCreate: false})
                   }}>
            <h2 className={"text-primary"}>Új munkavédelmi előírás</h2>
            <SafetyFormComponent method="post"/>
          </Sidebar>
          <Sidebar position="left" visible={window.App.state.sidebarSafetyUpdate} closeOnEscape={true}
                   style={{width: '98%'}}
                   onHide={() => {
                     window.App.setState({sidebarSafetyUpdate: false})
                   }}>
            <h2 className={"text-primary"}>Munkavédelmi előírás módosítása</h2>
            <SafetyFormComponent method="put" initialValues={window.App.state.props}/>
          </Sidebar>
          <Sidebar position="left" visible={window.App.state.sidebarSafetyeducationCreate} closeOnEscape={true}
                   style={{width: '780px'}}
                   onHide={() => {
                     window.App.setState({sidebarSafetyeducationCreate: false})
                   }}>
            <h2 className={"text-primary"}>Új munkavédelmi oktatás</h2>
            <SafetyeducationFormComponent method="post"/>
          </Sidebar>
          <Sidebar position="left" visible={window.App.state.sidebarSafetyeducationUpdate} closeOnEscape={true}
                   style={{width: '780px'}}
                   onHide={() => {
                     window.App.setState({sidebarSafetyeducationUpdate: false})
                   }}>
            <h2 className={"text-primary"}>Munkavédelmi oktatás módosítása</h2>
            <SafetyeducationFormComponent method="put" initialValues={window.App.state.props}/>
          </Sidebar>
          <Sidebar position="left" visible={window.App.state.sidebarSafetyeducationParticipants} closeOnEscape={true}
                   style={{width: '98%'}}
                   onHide={() => window.App.setState({sidebarSafetyeducationParticipants: false})}>
            <h2 className={"text-primary"}>Munkavédelmi oktatás résztvevői</h2>
            <SafetyeducationParticipantsComponent initialValues={window.App.state.props}/>
          </Sidebar>
          <Sidebar position="left" visible={window.App.state.sidebarSafetyeducationResults} closeOnEscape={true}
                   style={{width: '98%'}}
                   onHide={() => window.App.setState({sidebarSafetyeducationResults: false})}>
            <h2 className={"text-primary"}>Munkavédelmi oktatás eredményei</h2>
            <SafetyeducationResultsComponent initialValues={window.App.state.props}/>
          </Sidebar>
          <Sidebar position="left" visible={window.App.state.sidebarWorktypeCreate} closeOnEscape={true}
                   style={{width: '98%'}}
                   onHide={() => {
                     window.App.setState({sidebarWorktypeCreate: false})
                   }}>
            <h2 className={"text-primary"}>Új munkatípus</h2>
            <WorktypeFormComponent method="post"/>
          </Sidebar>
          <Sidebar position="left" visible={window.App.state.sidebarWorktypeUpdate} closeOnEscape={true}
                   style={{width: '98%'}}
                   onHide={() => {
                     window.App.setState({sidebarWorktypeUpdate: false})
                   }}>
            <h2 className={"text-primary"}>Munkatípus módosítása</h2>
            <WorktypeFormComponent method="put" initialValues={window.App.state.props}/>
          </Sidebar>
          <Sidebar position="left" visible={window.App.state.sidebarWorktypeShow} closeOnEscape={true}
                   style={{width: '98%'}}
                   onHide={() => {
                     window.App.setState({sidebarWorktypeShow: false})
                   }}>
            <h2 className={"text-primary"}>Munkatípus megtekintése</h2>
            <WorktypeShowComponent initialValues={window.App.state.props}/>
          </Sidebar>

        </>
      ) : (
        <p className={"p-text-center p-my-5 p-py-5"}>
          <i className={"pi pi-exclamation-circle p-d-block p-mb-5"} style={{'fontSize': '5em'}}></i>
          <span className={"p-text-bold p-d-block "}>Ehhez a modulhoz sajnos nem rendelkezel hozzáféréssel.</span>
          Amennyiben további információra van szükséged írj emailt a {process.env.REACT_APP_DEVOPS_CONTACT} címre.
          {process.env.REACT_APP_SUBSCRIPTIONS === 'enabled' &&
          <span className={"p-text-bold p-d-block p-mt-5"}>
                       <a className={"p-button p-button-warning"}
                          onClick={() => {
                            window.location.replace('/#/subscriptions');
                            window.App.forceUpdate()
                          }}
                       >
                         <i className={"pi pi-building"}></i>&nbsp;Előfizetői fiók áttekintése
                       </a>
                   </span>
          }
        </p>
      )}
    </div>
  )
}
export default withTranslation('common')(SafetyDashboardPage);
