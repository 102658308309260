import React, {useState, useEffect, useRef} from 'react';
import {withTranslation} from 'react-i18next';
import {Form, Field} from 'react-final-form';
import {classNames} from 'primereact/utils';
import {InputText} from 'primereact/inputtext';
import {Button} from 'primereact/button';
import {Panel} from "primereact/panel";
import {Dropdown} from "primereact/dropdown";
import {Calendar} from "primereact/calendar";
import {addLocale} from "primereact/api";
import {Dialog} from "primereact/dialog";
import {AutoComplete} from "primereact/autocomplete";
import {BlockUI} from "primereact/blockui";
import {DataTable} from "primereact/datatable";
import {Column} from "primereact/column";
import moment from "moment";
import {FileUpload} from "primereact/fileupload";
import {InputNumber} from "primereact/inputnumber";
import {Tooltip} from "primereact/tooltip";
import {GMap} from 'primereact/gmap';
//import { loadGoogleMaps, removeGoogleMaps } from './GoogleMaps';
import {InputMask} from "primereact/inputmask";

import ProjectFormService from '../../services/project/ProjectFormService'
import CurrencyListService from "../../services/currency/CurrencyListService";
import ProjecttypeListService from "../../services/projecttype/ProjecttypeListService";
import ProjectstatusListService from "../../services/projectstatus/ProjectstatusListService";
import CosttypeListService from "../../services/costtype/CosttypeListService";
import PartnerListService from "../../services/partner/PartnerListService";
import PartnerFormComponent from "../partner/PartnerFormComponent";
import VatListService from "../../services/vat/VatListService";
import WarehouseListService from "../../services/warehouse/WarehouseListService";
import ProjectWarehouseCreateService from "../../services/project/ProjectWarehouseCreateService";
import ProjecttypeShowService from "../../services/projecttype/ProjecttypeShowService";

const ProjectFormComponent = (props) => {

  const [formData, setFormData] = useState({
    currency: "HUF"
  });
  const [loading, setLoading] = useState(false);
  const [fields, setFields] = useState({});
  const [currencies, setCurrencies] = useState([]);
  const [costtypes, setCosttypes] = useState([]);

  const validate = (data) => {
    let errors = {};
    // if (!data.name) {
    //   errors.name = 'Az megnevezés nem lehet üres';
    // }
    // if (!data.netAmount) {
    //   errors.netAmount = 'Az összeg nem lehet üres';
    // }
    // if (!data.projectType) {
    //   errors.projectType = 'A típus nem lehet üres';
    // }
    // if (!data.projectStatus) {
    //   errors.projectStatus = 'A státusz nem lehet üres';
    // }
    // if (!data.currency) {
    //   errors.currency = 'A pénznem kötelező';
    // }
    return errors;
  };
  const onSubmit = (data, form) => {
    setLoading(true)
    setFormData({
      ...formData,
      data
    });
    if (props && props.initialValues || formData.id) {
      ProjectFormService(data, 'put')
        .then(response => {
          window.App.toastShow('success', 'Sikeres módosítás!');
          window.App.setState({
            rerenderProjectList: true,
            newPartnerId: null,
            newPartnerName: null,
          })
          if (props.closeForm === true) {
            window.App.setState({
              sidebarProjectUpdate: false,
              sidebarProjectCreate: false
            })
          }
          setLoading(false)
        })
        .catch(error => {
          window.App.toastShow('error', 'Hiba történt!', error.toString(), '', error.response);
          setLoading(false)
        })
    } else {
      ProjectFormService(data, 'post')
        .then(response => {
          window.App.toastShow('success', 'Sikeres mentés!');
          setFormData({...data, id: response.data.id})
          window.App.setState({
            rerenderProjectList: true,
            newPartnerId: null,
            newPartnerName: null,
          })
          if (props.closeForm === true) {
            window.App.setState({
              sidebarProjectCreate: false
            })
          }
          setLoading(false)
        })
        .catch(error => {
          window.App.toastShow('error', 'Hiba történt!', error.toString(), '', error.response);
          setLoading(false)
        })
    }
    form.restart();
  };
  const isFormFieldValid = (meta) => !!(meta.touched && meta.error);
  const getFormErrorMessage = (meta) => {
    return isFormFieldValid(meta) && <small className="p-error">{meta.error}</small>;
  };

  // partners autocomplete
  const [partners, setPartners] = useState([]);
  const [selectedPartner, setSelectedPartner] = useState('');

  const searchPartners = (event) => {
    //setSelectedPartner(event.query.replace('Nincs találat', '').replace(': ', ''))
    //setSelectedPartner(event.query)
    PartnerListService(JSON.stringify({filters: {name: {
      //value: event.query.replace('Nincs találat', '').replace(': ', '')
      value: event.query
    }}}))
      .then(response => {
        setPartners(response.data.items)
        // if (response.data && response.data.items && response.data.items[0]) {
        //   setPartners(response.data.items)
        // } else {
        //   let string
        //   if (event.query) {
        //     string = 'Nincs találat: ' + event.query.replace('Nincs találat', '').replace(': ', '')
        //   } else {
        //     string = 'Nincs találat'
        //   }
        //   setSelectedPartner(string)
        // }
      })
      .catch(error => {
        window.App.toastShow('error', 'Hiba történt!', error.toString(), '', error.response);
      })
  }

  addLocale('hu', {
    firstDayOfWeek: 1,
    dayNames: ['vasárnap', 'hétfő', 'kedd', 'szerda', 'csütörtök', 'péntek', 'szombat'],
    dayNamesShort: ['vas', 'hé', 'ke', 'sze', 'csü', 'pé', 'szo'],
    dayNamesMin: ['V', 'H', 'K', 'SZ', 'CS', 'P', 'SZ'],
    monthNames: ['január', 'február', 'március', 'április', 'május', 'június', 'július', 'augusztus', 'szeptember',
      'október', 'november', 'december'],
    monthNamesShort: ['jan', 'feb', 'mar', 'ápr', 'máj', 'jun', 'júl', 'aug', 'szep', 'okt', 'nov', 'dec'],
    today: 'Ma',
    clear: 'Töröl'
  });

  // vat
  const [vats, setVats] = useState([]);

  // projectType autocomplete
  const [projectTypes, setProjectTypes] = useState([]);
  const [selectedProjectType, setSelectedProjectType] = useState('');

  const searchProjectType = (event) => {
    //setSelectedProjectType(event.query.replace('Nincs találat', '').replace(': ', ''))
    //setSelectedProjectType(event.query)
    ProjecttypeListService(JSON.stringify({filters: {name: {
      //value: event.query.replace('Nincs találat', '').replace(': ', '')
      value: event.query
    }}}))
      .then(response => {
        setProjectTypes(response.data.items)
        // if (response.data && response.data.items && response.data.items[0]) {
        //   setProjectTypes(response.data.items)
        // } else {
        //   let string
        //   if (event.query) {
        //     string = 'Nincs találat: ' + event.query.replace('Nincs találat', '').replace(': ', '')
        //   } else {
        //     string = 'Nincs találat'
        //   }
        //   setSelectedProjectType(string)
        // }
      })
      .catch(error => {
        window.App.toastShow('error', 'Hiba történt!', error.toString(), '', error.response);
      })
  }

  // projectStatus autocomplete
  const [projectStatuses, setProjectStatuses] = useState([]);
  const [selectedProjectStatus, setSelectedProjectStatus] = useState('');

  const searchProjectStatus = (event) => {
    //setSelectedProjectStatus(event.query.replace('Nincs találat', '').replace(': ', ''))
    //setSelectedProjectStatus(event.query)
    ProjectstatusListService(JSON.stringify({filters: {name: {value: event.query}}}))
      .then(response => {
        setProjectStatuses(response.data.items)
        // if (response.data && response.data.items && response.data.items[0]) {
        //   setProjectStatuses(response.data.items)
        // } else {
        //   let string
        //   if (event.query) {
        //     string = 'Nincs találat: ' + event.query
        //   } else {
        //     string = 'Nincs találat'
        //   }
        //   setSelectedProjectStatus(string)
        // }
      })
      .catch(error => {
        window.App.toastShow('error', 'Hiba történt!', error.toString(), '', error.response);
      })
  }

  // gmpap
  const [showMap, setShowMap] = useState(false);
  const google = window.google
  const loadGoogleMaps = (callback) => {
    const existingScript = document.getElementById('googleMaps');

    if (!existingScript) {
      const script = document.createElement('script');
      script.src = 'https://maps.google.com/maps/api/js?key=' + process.env.REACT_APP_GMAP_KEY;
      script.id = 'googleMaps';
      script.async = true;
      script.defer = true;
      document.body.appendChild(script);

      script.onload = () => {
        if (callback) callback();
      };
    }

    if (existingScript && callback) callback();
  };

  // const removeGoogleMaps = () => {
  //   const mapScript = document.getElementById('googleMaps');
  //
  //   if (mapScript) {
  //     mapScript.parentNode.removeChild(mapScript);
  //
  //     const head = document.getElementsByTagName('head')[0];
  //     const scripts = head.getElementsByTagName('script');
  //     for (let i = 0; i < scripts.length; i++) {
  //       let script = scripts[i];
  //       let src = script.src;
  //
  //       if (src.startsWith('https://maps.google.com/maps')) {
  //         head.removeChild(script);
  //       }
  //     }
  //   }
  // };

  const [googleMapsReady, setGoogleMapsReady] = useState(false);
  const [mapDialogVisible, setMapDialogVisible] = useState(false);
  const [mapOverlays, setMapOverlays] = useState(null);
  const [mapSelectedPosition, setMapSelectedPosition] = useState(null);
  const toast = useRef(null);

  //const toast = useRef(null);
  const infoWindow = useRef(null);

  const onMapClick = (event) => {
    setMapDialogVisible(true);
    setMapSelectedPosition(event.latLng)
  }

  const onOverlayClick = (event) => {
    let isMarker = event.overlay.getTitle !== undefined;

    if (isMarker) {
      let title = event.overlay.getTitle();
      infoWindow.current = infoWindow.current || new google.maps.InfoWindow();
      infoWindow.setContent('<div>' + title + '</div>');
      infoWindow.open(event.map, event.overlay);
      event.map.setCenter(event.overlay.getPosition());

      toast.current.show({severity: 'info', summary: 'Marker Selected', detail: title});
    } else {
      toast.current.show({severity: 'info', summary: 'Shape Selected', detail: ''});
    }
  }

  const addMarker = () => {
    let newMarker = new google.maps.Marker({
      position: {
        lat: mapSelectedPosition.lat(),
        lng: mapSelectedPosition.lng()
      },
    });

    setMapOverlays([newMarker]);
    setMapDialogVisible(false);
    setFormData({
      ...formData,
      lng: mapSelectedPosition.lng(),
      lat: mapSelectedPosition.lat()
    })
  }

  const onMapReady = (event) => {
    if (props && props.initialValues && props.initialValues.lat && props.initialValues.lng) {
      setMapOverlays(
        [
          new google.maps.Marker({position: {lat: props.initialValues.lat, lng: props.initialValues.lng}, title: ''}),
        ]
      );
    } else {
      setMapOverlays(
        []
      );
    }
  }

  const onMapHide = (event) => {
    setMapDialogVisible(false);
  }

  const mapOptions = {
    center: {lat: 47.49982405077617, lng: 19.064711250233742},
    zoom: 10
  };

  const mapFooter = <div>
    <Button label="Mentés" icon="pi pi-check" className={"p-button-success"} onClick={addMarker}/>
    <Button label="Mégse" className={"p-button-secondary"} onClick={onMapHide}/>
  </div>;

  // import
  const [importLoading, setImportLoading] = useState(false);
  const [importFormData, setImportFormData] = useState({});

  const importValidate = (data) => {
    let errors = {};
    return errors;
  };

  const fileUpload = (files) => {
    var reader = new FileReader();
    let name = files.files[0].name
    reader.onloadend = () => {
      let base64data = reader.result;
      setImportFormData({
        ...importFormData,
        file: base64data.toString().replace(/^data:(.*,)?/, ''),
        originalFileName: name
      })
    }
    reader.readAsDataURL(files.files[0])
  }

  // warehouse autocomplete
  const [warehousesFrom, setWarehousesFrom] = useState([]);

  const searchWarehouse = (event) => {
    //window.App.setState({valueWarehouseName: event.query.replace('Nincs találat', '').replace(': ', '')})
    //window.App.setState({valueWarehouseName: event.query})
    WarehouseListService(JSON.stringify({filters: {name: {
      //value: event.query.replace('Nincs találat', '').replace(': ', '')
      value: event.query
    }}}))
      .then(response => {
        setWarehousesFrom(response.data.items)
        // if (response.data && response.data.items && response.data.items[0]) {
        //   setWarehousesFrom(response.data.items)
        // } else {
        //   let string
        //   if (event.query) {
        //     string = 'Nincs találat: ' + event.query.replace('Nincs találat', '').replace(': ', '')
        //   } else {
        //     string = 'Nincs találat'
        //   }
        //   window.App.setState({valueWarehouseName: string})
        // }
      })
      .catch(error => {
        window.App.toastShow('error', 'Hiba történt!', error.toString(), '', error.response);
      })
  }

  useEffect(() => {
    if (window.App.state.newPartnerId) {
      setFormData({
        ...formData,
        partner: {id: window.App.state.newPartnerId}
      })
      setSelectedPartner(window.App.state.newPartnerName)
    } else {
      loadGoogleMaps(() => {
        setGoogleMapsReady(true);
      });
      // return () => {
      //   removeGoogleMaps();
      // }
      CurrencyListService()
        .then(response => {
          setCurrencies(response.data)
        })
        .catch(error => {
          window.App.toastShow('error', 'Hiba történt!', error.toString(), '', error.response);
        })
      CosttypeListService()
        .then(response => {
          setCosttypes(response.data.items)
        })
        .catch(error => {
          window.App.toastShow('error', 'Hiba történt!', error.toString(), '', error.response);
        })
      VatListService()
        .then(response => {
          setVats(response.data)
        })
        .catch(error => {
          window.App.toastShow('error', 'Hiba történt!', error.toString(), '', error.response);
        })
      if (props && props.initialValues) {
        if (props.initialValues.projectType && props.initialValues.projectType.id) {
          ProjecttypeShowService(props.initialValues.projectType.id)
            .then(response => {
              setFields(response.data.fields)
            })
            .catch(error => {
              window.App.toastShow('error', 'Hiba történt!', error.toString(), '', error.response);
            })
        }
        setFormData(
          props.initialValues
        )
        if (props.initialValues.projectType && props.initialValues.projectTypeName) {
          setSelectedProjectType(props.initialValues.projectTypeName)
        }
        if (props.initialValues.projectStatus && props.initialValues.projectStatusName) {
          setSelectedProjectStatus(props.initialValues.projectStatusName)
        }
        if (props.initialValues.partner && props.initialValues.partnerName) {
          setSelectedPartner(props.initialValues.partnerName)
        }
        if (props.initialValues.warehouse && props.initialValues.warehouseName) {
          window.App.setState({valueWarehouseName: props.initialValues.warehouseName})
        }
      }
    }
  }, [window.App.state.newPartnerId])

  return (
    <Form onSubmit={onSubmit} initialValues={formData}
          validate={validate} render={({handleSubmit}) => (
      <form onSubmit={handleSubmit} className="">
        <Panel headerTemplate={
          <div className={"p-grid"}>
            <div className={"p-col-12"}>
              <h3 className={"p-text-secondary p-mt-0"}>
                <i className={"pi pi-list"}></i> Alapadatok
              </h3>
            </div>
          </div>
        } className={""}>
          <div className={"p-grid"}>
            <div className={"p-col-12 p-md-6 p-lg-6"}>
              <Field name="name" render={({input, meta}) => (
                <div className="p-field p-fluid">
                  <label htmlFor="name"
                         className={classNames({'p-error': isFormFieldValid(meta)})}>Megnevezés</label>
                  <span className="p-d-block">
                      <InputText id="name" {...input}
                                 onChange={(e) => {
                                   setFormData({
                                     ...formData,
                                     name: e.target.value
                                   })
                                 }}
                                 value={formData.name}
                                 className={classNames({'p-invalid': isFormFieldValid(meta)})}/>
                    </span>
                  {getFormErrorMessage(meta)}
                </div>
              )}/>
            </div>
            <div className={"p-col-12 p-lg-6 p-xl-6"}>
              <Field name="partner" render={({input, meta}) => (
                <div className="p-field p-fluid">
                  <label htmlFor="partner"
                         className={classNames({'p-error': isFormFieldValid(meta)})}>Vevő
                  </label>
                  <span className="p-inputgroup">
                              <AutoComplete value={selectedPartner}
                                            id={"partner"}
                                            suggestions={partners}
                                            forceSelection
                                            completeMethod={searchPartners}
                                            field={"name"}
                                            delay="1000"
                                            dropdown
                                            placeholder={"Keresés gépeléssel..."}
                                            className={classNames({'p-invalid': isFormFieldValid(meta)})}
                                            onSelect={(e) => {
                                              setFormData({
                                                ...formData,
                                                partner: {id: e.value.id}
                                              })
                                            }}
                                            onChange={(e) => {
                                              setSelectedPartner(e.value)
                                              setFormData({
                                                ...formData,
                                                partner: null
                                              })
                                            }}
                                            onClear={(e) => {
                                              setSelectedPartner('')
                                              setFormData({
                                                ...formData,
                                                partner: null
                                              })
                                            }}
                              />
                              <Button type="button" className={"p-button"}
                                      onClick={() => {
                                        window.App.setState({sidebarPartnerCreate: true})
                                      }}>
                                <i className={"pi pi-window-maximize"}></i>
                              </Button>
                            </span>
                  {getFormErrorMessage(meta)}
                </div>
              )}/>
            </div>
            <div className={"p-col-12 p-md-12 p-lg-12"}>
              <Field name="description" render={({input, meta}) => (
                <div className="p-field p-fluid">
                  <label htmlFor="description"
                         className={classNames({'p-error': isFormFieldValid(meta)})}>Leírás</label>
                  <span className="p-d-block">
                      <InputText id="description" {...input}
                                 onChange={(e) => {
                                   setFormData({
                                     ...formData,
                                     description: e.target.value
                                   })
                                 }}
                                 value={formData.description}
                                 className={classNames({'p-invalid': isFormFieldValid(meta)})}/>
                    </span>
                  {getFormErrorMessage(meta)}
                </div>
              )}/>
            </div>
          </div>
          <div className={"p-grid"}>
            <div className={"p-col-12 p-lg-6 p-xl-6"}>
              <div className={"p-grid p-mt-1"}>
                <div className={"p-col-12"}>
                  <h3 className={"p-text-secondary"}>
                    <i className={"pi pi-money-bill"}></i> Pénzügy
                  </h3>
                </div>
              </div>
              <div className={"p-grid"}>
                <div className={"p-col-12 p-md-3 p-lg-3"}>
                  <Field name="currency" render={({input, meta}) => (
                    <div className="p-field p-fluid">
                      <label htmlFor="currency"
                             className={classNames({'p-error': isFormFieldValid(meta)})}>Pénznem</label>
                      <span className="p-d-block">
                    <Dropdown options={currencies}
                              optionLabel="code"
                              optionValue="code"
                              id={"currency"}
                              emptyMessage={"Még nincs pénznem..."}
                              onChange={(e) => {
                                setFormData({
                                  ...formData,
                                  currency: e.value
                                })
                              }}
                              value={formData.currency}
                    />
                  </span>
                      {getFormErrorMessage(meta)}
                    </div>
                  )}/>
                </div>
                {fields && fields.netAmound && fields.netAmound === true &&
                <div className={"p-col-12 p-md-9 p-lg-9"}>
                  <Field name="netAmount" render={({input, meta}) => (
                    <div className="p-field p-fluid">
                      <label htmlFor="netAmount"
                             className={classNames({'p-error': isFormFieldValid(meta)})}>Nettó összeg</label>
                      <span className={"p-d-block"}>
                    {formData && formData.currency ? (
                      <InputNumber {...input} id="netAmount"
                                   locale="hu-HU"
                                   minFractionDigits={0}
                                   maxFractionDigits={9}
                                   value={formData.netAmount}
                                   onChange={(e) => {
                                     setFormData({
                                       ...formData,
                                       netAmount: e.value
                                     })
                                   }}
                                   className={classNames({'p-invalid': isFormFieldValid(meta)})}/>
                    ) : (
                      <InputNumber {...input} id="netAmount"
                                   locale="hu-HU"
                                   minFractionDigits={0}
                                   maxFractionDigits={9}
                                   value={formData.netAmount}
                                   onChange={(e) => {
                                     setFormData({
                                       ...formData,
                                       netAmount: e.value
                                     })
                                   }}
                                   className={classNames({'p-invalid': isFormFieldValid(meta)})}/>
                    )}
                    </span>
                      {getFormErrorMessage(meta)}
                    </div>
                  )}/>
                </div>
                }
                {/*<div className={"p-col-12 p-md-5 p-lg-5"}>*/}
                {/*  <Field name="extraWorkAmount" render={({input, meta}) => (*/}
                {/*    <div className="p-field p-fluid">*/}
                {/*      <label htmlFor="extraWorkAmount"*/}
                {/*             className={classNames({'p-error': isFormFieldValid(meta)})}>Pótmunka összege</label>*/}
                {/*      <span>*/}
                {/*    {formData && formData.currency ? (*/}
                {/*      <InputNumber id="extraWorkAmount" {...input}*/}
                {/*                   // mode="currency"*/}
                {/*                   // currency={formData.currency}*/}
                {/*                   minFractionDigits={0}*/}
                {/*                   maxFractionDigits={9}*/}
                {/*                   locale="hu-HU"*/}
                {/*                   onChange={(e) => {*/}
                {/*                     setFormData({*/}
                {/*                       ...formData,*/}
                {/*                       extraWorkAmount: e.value*/}
                {/*                     })*/}
                {/*                   }}*/}
                {/*                   className={classNames({'p-invalid': isFormFieldValid(meta)})}/>*/}
                {/*    ) : (*/}
                {/*      <InputNumber id="extraWorkAmount" {...input}*/}
                {/*                   locale="hu-HU"*/}
                {/*                   minFractionDigits={0}*/}
                {/*                   maxFractionDigits={9}*/}
                {/*                   onChange={(e) => {*/}
                {/*                     setFormData({*/}
                {/*                       ...formData,*/}
                {/*                       extraWorkAmount: e.value*/}
                {/*                     })*/}
                {/*                   }}*/}
                {/*                   className={classNames({'p-invalid': isFormFieldValid(meta)})}/>*/}
                {/*    )}*/}
                {/*    </span>*/}
                {/*      {getFormErrorMessage(meta)}*/}
                {/*    </div>*/}
                {/*  )}/>*/}
                {/*</div>*/}
              </div>
            </div>
            <div className={"p-col-12 p-lg-6 p-xl-6"}>
              <div className={"p-grid p-mt-1"}>
                <div className={"p-col-12"}>
                  <h3 className={"p-text-secondary"}>
                    <i className={"pi pi-info-circle"}></i> Tulajdonságok
                  </h3>
                </div>
              </div>
              <div className={"p-grid"}>
                <div className={"p-col-12 p-md-6 p-lg-6"}>
                  <Field name="projectType" render={({input, meta}) => (
                    <div className="p-field p-fluid">
                      <label htmlFor="projectType"
                             className={classNames({'p-error': isFormFieldValid(meta)})}>Típus</label>
                      <span className={"p-d-block"}>
                        <AutoComplete value={selectedProjectType}
                                      id={"projectType"}
                                      suggestions={projectTypes}
                                      forceSelection
                                      completeMethod={searchProjectType}
                                      field={"name"}
                                      delay="1000"
                                      placeholder={"Keresés gépeléssel..."}
                                      className={classNames({'p-invalid': isFormFieldValid(meta)})}
                                      onSelect={(e) => {
                                        setFormData({
                                          ...formData,
                                          projectType: {id: e.value.id}
                                        })
                                        ProjecttypeShowService(e.value.id)
                                          .then(response => {
                                            setFields(response.data.fields)
                                          })
                                          .catch(error => {
                                            window.App.toastShow('error', 'Hiba történt!', error.toString(), '', error.response);
                                          })
                                      }}
                                      onChange={(e) => {
                                        setSelectedProjectType(e.value)
                                        setFormData({
                                          ...formData,
                                          projectType: null
                                        })
                                      }}
                                      dropdown
                                      onClear={(e) => {
                                        setSelectedProjectType('')
                                        setFormData({
                                          ...formData,
                                          projectType: null
                                        })
                                      }}
                        />
                      </span>
                      {getFormErrorMessage(meta)}
                    </div>
                  )}/>
                </div>
                <div className={"p-col-12 p-md-6 p-lg-6"}>
                  <Field name="projectStatus" render={({input, meta}) => (
                    <div className="p-field p-fluid">
                      <label htmlFor="projectStatus"
                             className={classNames({'p-error': isFormFieldValid(meta)})}>Státusz</label>
                      <span className={"p-d-block"}>
                        <AutoComplete value={selectedProjectStatus}
                                      id={"projectStatus"}
                                      suggestions={projectStatuses}
                                      forceSelection
                                      completeMethod={searchProjectStatus}
                                      field={"name"}
                                      delay="1000"
                                      placeholder={"Keresés gépeléssel..."}
                                      className={classNames({'p-invalid': isFormFieldValid(meta)})}
                                      onSelect={(e) => {
                                        setFormData({
                                          ...formData,
                                          projectStatus: {id: e.value.id}
                                        })
                                      }}
                                      onChange={(e) => {
                                        setSelectedProjectStatus(e.value)
                                        setFormData({
                                          ...formData,
                                          projectStatus: null
                                        })
                                      }}
                                      dropdown
                                      onClear={(e) => {
                                        setSelectedProjectStatus('')
                                        setFormData({
                                          ...formData,
                                          projectStatus: null
                                        })
                                      }}
                        />
                  </span>
                      {getFormErrorMessage(meta)}
                    </div>
                  )}/>
                </div>
              </div>
            </div>
          </div>
          <div className={"p-grid"}>
            <div className={"p-col-12 p-lg-5 p-xl-5"}>
              <div className={"p-grid"}>
                <div className={"p-col-12"}>
                  <h3 className={"p-text-secondary"}>
                    <i className={"pi pi-file"}></i> Szerződés
                  </h3>
                </div>
              </div>
              <div className={"p-grid"}>
                {fields && fields.contractNumber && fields.contractNumber === true &&
                <div className={"p-col"}>
                  <Field name="contractNumber" render={({input, meta}) => (
                    <div className="p-field p-fluid">
                      <label htmlFor="contractNumber"
                             className={classNames({'p-error': isFormFieldValid(meta)})}>Szerződésszám</label>
                      <span className={"p-d-block"}>
                      <InputText id="contractNumber" {...input}
                                 onChange={(e) => {
                                   setFormData({
                                     ...formData,
                                     contractNumber: e.target.value
                                   })
                                 }}
                                 value={formData.contractNumber}
                                 className={classNames({'p-invalid': isFormFieldValid(meta)})}/>
                    </span>
                      {getFormErrorMessage(meta)}
                    </div>
                  )}/>
                </div>
                }
                <div className={"p-col"}>
                  <Field name="projectNumber" render={({input, meta}) => (
                    <div className="p-field p-fluid">
                      <label htmlFor="projectNumber"
                             className={classNames({'p-error': isFormFieldValid(meta)})}>Projektszám</label>
                      <span className={"p-d-block"}>
                      <InputText id="projectNumber" {...input}
                                 onChange={(e) => {
                                   setFormData({
                                     ...formData,
                                     projectNumber: e.target.value
                                   })
                                 }}
                                 value={formData.projectNumber}
                                 className={classNames({'p-invalid': isFormFieldValid(meta)})}/>
                    </span>
                      {getFormErrorMessage(meta)}
                    </div>
                  )}/>
                </div>
              </div>
            </div>
            {fields && ((fields.deadline && fields.deadline === true) || (fields.startedAt && fields.startedAt === true) || (fields.guaranteeEndAt && fields.guaranteeEndAt === true)) &&
            <div className={"p-col-12 p-lg-7 p-xl-7"}>
              <div className={"p-grid"}>
                <div className={"p-col-12"}>
                  <h3 className={"p-text-secondary"}>
                    <i className={"pi pi-calendar"}></i> Határidők
                  </h3>
                </div>
              </div>
              <div className={"p-grid"}>
                {fields && fields.deadline && fields.deadline === true &&
                <div className={"p-col"}>
                  <Field name="deadline" render={({input, meta}) => (
                    <div className="p-field p-fluid">
                      <label htmlFor="deadline"
                             className={classNames({'p-error': isFormFieldValid(meta)})}>Határidő</label>
                      <span className={"p-d-block"}>
                        <InputMask id={"deadline"}
                                   mask={"9999-99-99"}
                                   placeholder={"ÉÉÉÉ-HH-NN"}
                                   value={formData.deadline}
                                   onChange={(e) => {
                                     if (e.value.indexOf("_") === -1) {
                                       if (e.value === '') {
                                         e.value = null
                                       }
                                       setFormData({
                                         ...formData,
                                         deadline: e.value
                                       })
                                     }
                                   }}
                        />
                    </span>
                      {getFormErrorMessage(meta)}
                    </div>
                  )}/>
                </div>
                }
                {fields && fields.startedAt && fields.startedAt === true &&
                <div className={"p-col"}>
                  <Field name="startedAt" render={({input, meta}) => (
                    <div className="p-field p-fluid">
                      <label htmlFor="startedAt"
                             className={classNames({'p-error': isFormFieldValid(meta)})}>Munkakezdés</label>
                      <span className={"p-d-block"}>
                        <InputMask id={"startedAt"}
                                   mask={"9999-99-99"}
                                   placeholder={"ÉÉÉÉ-HH-NN"}
                                   value={formData.startedAt}
                                   onChange={(e) => {
                                     if (e.value.indexOf("_") === -1) {
                                       if (e.value === '') {
                                         e.value = null
                                       }
                                       setFormData({
                                         ...formData,
                                         startedAt: e.value
                                       })
                                     }
                                   }}
                        />
                    </span>
                      {getFormErrorMessage(meta)}
                    </div>
                  )}/>
                </div>
                }
                {fields && fields.guaranteeEndAt && fields.guaranteeEndAt === true &&
                <div className={"p-col"}>
                  <Field name="guaranteeEndAt" render={({input, meta}) => (
                    <div className="p-field p-fluid">
                      <label htmlFor="guaranteeEndAt"
                             className={classNames({'p-error': isFormFieldValid(meta)})}>Garancia lejárat</label>
                      <span className={"p-d-block"}>
                        <InputMask id={"guaranteeEndAt"}
                                   mask={"9999-99-99"}
                                   placeholder={"ÉÉÉÉ-HH-NN"}
                                   value={formData.guaranteeEndAt}
                                   onChange={(e) => {
                                     if (e.value.indexOf("_") === -1) {
                                       if (e.value === '') {
                                         e.value = null
                                       }
                                       setFormData({
                                         ...formData,
                                         guaranteeEndAt: e.value
                                       })
                                     }
                                   }}
                        />
                    </span>
                      {getFormErrorMessage(meta)}
                    </div>
                  )}/>
                </div>
                }
              </div>
            </div>
            }
          </div>
          {fields && fields.location && fields.location === true &&
          <>
            <div className={"p-grid"}>
              <div className={"p-col-12 p-lg-6 p-xl-6"}>
                <h3 className={"p-text-secondary"}>
                  <i className={"pi pi-map"}></i> Lokáció
                </h3>
              </div>
              <div className={"p-col-6 p-lg-6 p-xl-6 p-text-right p-col-align-center"}>
                {showMap === false ? (
                  <Button className={"p-button-outlined p-button-sm"}
                          type={"button"}
                          style={{border: 'none'}}
                          label="Térkép nézet bekapcsolása" icon="pi pi-map"
                          onClick={() => {
                            setShowMap(true)
                          }}
                  />
                ) : (
                  <Button className={"p-button-outlined p-button-sm p-button-danger"}
                          type={"button"}
                          style={{border: 'none'}}
                          label="Térkép nézet kikapcsolása" icon="pi pi-map"
                          onClick={() => {
                            setShowMap(false)
                          }}
                  />
                )}
              </div>
            </div>
            <div className={"p-grid"}>
              {
                showMap === true && googleMapsReady && (
                  <div className={"p-col-12"}>
                    <div className="card">
                      <GMap overlays={mapOverlays} options={mapOptions} style={{width: '100%', minHeight: '320px'}}
                            onMapReady={onMapReady}
                            onMapClick={onMapClick} onOverlayClick={onOverlayClick}/>
                    </div>
                  </div>
                )
              }
              <Dialog header="Lokáció kiválasztása" visible={mapDialogVisible} width="300px" modal footer={mapFooter}
                      onHide={onMapHide}>
                <div className="grid p-fluid">
                  <div className="col-2" style={{paddingTop: '.75em'}}>Lat</div>
                  <div className="col-10"><InputText readOnly
                                                     value={mapSelectedPosition ? mapSelectedPosition.lat() : ''}/>
                  </div>
                  <div className="col-2" style={{paddingTop: '.75em'}}>Lng</div>
                  <div className="col-10"><InputText readOnly
                                                     value={mapSelectedPosition ? mapSelectedPosition.lng() : ''}/>
                  </div>
                </div>
              </Dialog>
              <div className={"p-col-12 p-lg-6 p-xl-6"}>
                <Field name="lat" render={({input, meta}) => (
                  <div className="p-field p-fluid">
                    <label htmlFor="lat"
                           className={classNames({'p-error': isFormFieldValid(meta)})}>Szélesség</label>
                    <span className={"p-d-block"}>
                      <InputNumber id="lat" {...input}
                                   format={false}
                                   minFractionDigits={0}
                                   maxFractionDigits={20}
                                   onChange={(e) => {
                                     setFormData({
                                       ...formData,
                                       lat: e.value
                                     })
                                   }}
                                   value={formData.lat}
                                   className={classNames({'p-invalid': isFormFieldValid(meta)})}/>
                    </span>
                    {getFormErrorMessage(meta)}
                  </div>
                )}/>
              </div>
              <div className={"p-col-12 p-lg-6 p-xl-6"}>
                <Field name="lng" render={({input, meta}) => (
                  <div className="p-field p-fluid">
                    <label htmlFor="lng"
                           className={classNames({'p-error': isFormFieldValid(meta)})}>Hosszúság</label>
                    <span className={"p-d-block"}>
                      <InputNumber id="lng" {...input}
                                   format={false}
                                   minFractionDigits={0}
                                   maxFractionDigits={20}
                                   onChange={(e) => {
                                     setFormData({
                                       ...formData,
                                       lng: e.value
                                     })
                                   }}
                                   value={formData.lng}
                                   className={classNames({'p-invalid': isFormFieldValid(meta)})}/>
                    </span>
                    {getFormErrorMessage(meta)}
                  </div>
                )}/>
              </div>
            </div>
          </>
          }
          {fields && fields.warehouse && fields.warehouse === true &&
          <>
            <div className={"p-grid p-mt-1"}>
              <div className={"p-col-12 p-lg-6 p-xl-6"}>
                <h3 className={"p-text-secondary"}>
                  <i className={"pi pi-map"}></i> Egyéb
                </h3>
              </div>
            </div>
            <div className={"p-grid"}>
              <div className={"p-col-12 p-lg-6"}>
                <Field name="warehouse" render={({input, meta}) => (
                  <div className="p-field p-fluid">
                    <label htmlFor="warehouse"
                           className={classNames({'p-error': isFormFieldValid(meta)})}>Alapértelmezett raktár</label>
                    <span className="p-inputgroup">
                      <AutoComplete value={window.App.state.valueWarehouseName}
                                    id={"warehouse"}
                                    suggestions={warehousesFrom}
                                    forceSelection
                                    completeMethod={searchWarehouse}
                                    field={"name"}
                                    delay="1000"
                                    dropdown
                                    placeholder={"Keresés gépeléssel..."}
                                    className={classNames({'p-invalid': isFormFieldValid(meta)})}
                                    onSelect={(e) => {
                                      setFormData({
                                        ...formData,
                                        warehouse: {id: e.value.id},
                                        warehouseName: e.value.name
                                      })
                                    }}
                                    onChange={(e) => {
                                      window.App.setState({valueWarehouseName: e.value})
                                      setFormData({
                                        ...formData,
                                        warehouse: null,
                                        warehouseName: null
                                      })
                                    }}
                                    onClear={(e) => {
                                      window.App.setState({valueWarehouseName: ''})
                                      setFormData({
                                        ...formData,
                                        warehouse: null,
                                        warehouseName: null
                                      })
                                    }}
                      />
                      {/*<Button type="button" className={"p-button"}*/}
                      {/*        onClick={() => {*/}
                      {/*          window.App.setState({*/}
                      {/*            dialogWarehouse: true*/}
                      {/*          })*/}
                      {/*        }}>*/}
                      {/*  <i className={"pi pi-window-maximize"}></i>*/}
                      {/*</Button>*/}
                      {formData.id && !formData.warehouseName &&
                      <Button type="button" className={"p-button-success"}
                              onClick={() => {
                                ProjectWarehouseCreateService(formData.id)
                                  .then(response => {
                                    window.App.toastShow('success', 'Sikeres raktár létrehozás!');
                                    setFormData({
                                      ...formData,
                                      warehouse: {id: response.id},
                                      warehouseName: response.name
                                    })
                                  })
                                  .catch(error => {
                                    window.App.toastShow('error', 'Hiba történt!', error.toString(), '', error.response);
                                  })
                              }}>
                        <i className={"pi pi-plus"}></i>
                      </Button>
                      }
                      {/*<Dialog visible={window.App.state.dialogWarehouse}*/}
                      {/*        style={{width: '750px'}}*/}
                      {/*        header="Raktár hozzáadása"*/}
                      {/*        modal*/}
                      {/*        onHide={() => {*/}
                      {/*          window.App.setState({*/}
                      {/*            dialogWarehouse: false*/}
                      {/*          })*/}
                      {/*        }}>*/}
                      {/*  <WarehouseFormComponent/>*/}
                      {/*</Dialog>*/}
                      </span>
                    {getFormErrorMessage(meta)}
                  </div>
                )}/>
              </div>
            </div>
          </>
          }
          <div className={"p-grid p-mt-3"}>
            <div className={"p-col-12 p-lg-6 p-xl-6"}>
              {formData.id &&
              <Button type="button" label="Megtekintés" icon={"pi pi-eye"}
                      onClick={() => {
                        window.App.setState({
                          props: formData,
                          sidebarProjectUpdate: false,
                          sidebarProjectCreate: false,
                          sidebarProjectShow: true,
                        })
                      }}
                      className="p-button"/>
              }
            </div>
            <div className={"p-col-12 p-lg-6 p-xl-6 p-text-right"}>
              <Button type="submit" label="Mentés és bezár" icon={"pi pi-check"} loading={loading}
                      onClick={() => {
                        props = {...props, closeForm: true}
                      }}
                      className="p-button-success"/>
              <Button type="submit" label="Mentés" icon={"pi pi-check"} loading={loading}
                      onClick={() => {
                        props = {...props, closeForm: false}
                      }}
                      className="p-button-success p-ml-2"/>
            </div>
          </div>
        </Panel>
        <Dialog visible={window.App.state.sidebarPartnerCreate}
                style={{width: '750px'}}
                header="Partner hozzáadása"
                modal
                onHide={() => {
                  window.App.setState({sidebarPartnerCreate: false})
                }}>
          <PartnerFormComponent/>
        </Dialog>
      </form>
    )}/>
  )
}
export default withTranslation('common')(ProjectFormComponent);
