import React, {useState, useEffect, useRef} from 'react';
import {withTranslation} from 'react-i18next';
import {Panel} from "primereact/panel";
import {Dropdown} from "primereact/dropdown";
import {Column} from "primereact/column";
import {DataTable} from "primereact/datatable";
import {Button} from "primereact/button";
import {ProgressBar} from "primereact/progressbar";

import ScheduleitemsProductionItemsService from "../../services/scheduleitem/ScheduleitemsProductuonItemsService";
import ProductionitemListService from "../../services/productionitem/ProductionitemListService";
import ScheduleitemsProductionItemAddService from "../../services/scheduleitem/ScheduleitemsProductuonItemAddService";
import ScheduleitemsProductionItemDeleteService
  from "../../services/scheduleitem/ScheduleitemsProductuonItemDeleteService";
import ScheduleitemShowService from "../../services/scheduleitem/ScheduleitemShowService";
import ProductionitemStatusesService from "../../services/productionitem/ProductionitemStatusesService";
import {Field, Form} from "react-final-form";
import {classNames} from "primereact/utils";
import {InputSwitch} from "primereact/inputswitch";
import {InputText} from "primereact/inputtext";
import {Dialog} from "primereact/dialog";
import ProductionjobListService from "../../services/productionjob/ProductionjobListService";
import moment from "moment";
import {Message} from "primereact/message";
import {InputNumber} from "primereact/inputnumber";

const ScheduleitemscalendarListComponent = (props) => {

  const [formData, setFormData] = useState();
  const [loading, setLoading] = useState(false);
  const [estimatedTime, setEstimatedTime] = useState('');
  const [estimatedTimeDialog, setEstimatedTimeDialog] = useState(false);


  // production items added
  const [productionItemsAdded, setProductionItemsAdded] = useState([]);
  const [productionItemsAddedTotalRecords, setProductionItemsAddedTotalRecords] = useState(0);
  const [productionItemsAddedRows, setProductionItemsAddedRows] = useState(10);
  const [productionItemsAddedLazyParams, setProductionItemsAddedLazyParams] = useState({
    first: 0,
    rows: productionItemsAddedRows,
    page: 0,
    sortField: 'id',
    sortOrder: -1,
  });
  let productionItemsAddedLoadLazyTimeout = null;
  const productionItemsAddedLoadLazyData = () => {
    setLoading(true);
    if (productionItemsAddedLoadLazyTimeout) {
      clearTimeout(productionItemsAddedLoadLazyTimeout);
    }
    productionItemsAddedLoadLazyTimeout = setTimeout(() => {
      let _productionItemsAddedLazyParams = {
        ...productionItemsAddedLazyParams,
        // filters: {
        //   projectItem: {
        //     value: Number(props.projectitemId)
        //   }
        // }
        filters: {
          scheduleItem: {
            value: props.initialValues.id
          }
        }
      }
      ProductionjobListService(JSON.stringify(_productionItemsAddedLazyParams))
        .then(response => {
          setProductionItemsAddedTotalRecords(response.data.total_item_count);
          setProductionItemsAdded(response.data.items);
          setLoading(false);
        })
        .catch(error => {
          setLoading(false)
          window.App.toastShow('error', 'Hiba történt!', error.toString(), '', error.response);
        })
      // ScheduleitemsProductionItemsService(props.initialValues.id, JSON.stringify(_productionItemsAddedLazyParams))
      //   .then(response => {
      //     setProductionItemsAddedTotalRecords(response.data.total_item_count);
      //     setProductionItemsAdded(response.data.items);
      //     setLoading(false);
      //   })
      //   .catch(error => {
      //     setLoading(false)
      //     window.App.toastShow('error', 'Hiba történt!', error.toString(), '', error.response);
      //   })
    }, Math.random() * 1000 + 250);
  }
  const productionItemsAddedOnPage = (event) => {
    let _productionItemsAddedLazyParams = {...productionItemsAddedLazyParams, ...event};
    setProductionItemsAddedLazyParams(_productionItemsAddedLazyParams);
  }
  const productionItemsAddedOnSort = (event) => {
    let _productionItemsAddedLazyParams = {...productionItemsAddedLazyParams, ...event};
    setProductionItemsAddedLazyParams(_productionItemsAddedLazyParams);
  }
  const productionItemsAddedOnFilter = (event) => {
    let _productionItemsAddedLazyParams = {...productionItemsAddedLazyParams, ...event};
    _productionItemsAddedLazyParams['first'] = 0;
    _productionItemsAddedLazyParams['page'] = 0;
    setProductionItemsAddedLazyParams(_productionItemsAddedLazyParams);
  }
  const productionItemsAddedPager = {
    layout: 'RowsPerPageDropdown CurrentPageReport PrevPageLink NextPageLink',
    'RowsPerPageDropdown': (options) => {
      const dropdownOptions = [
        {label: 10, value: 10},
        {label: 20, value: 20},
        {label: 50, value: 50},
        {label: 200, value: 200}
      ];

      return (
        <>
          {/*<span className="p-mx-1" style={{color: 'var(--text-color)', userSelect: 'none'}}>Tételek egy oldalon: </span>*/}
          <Dropdown value={options.value} options={dropdownOptions}
                    onChange={(e) => setProductionItemsAddedRows(e.target.value)}
                    appendTo={document.body}/>
        </>
      );
    },
    'CurrentPageReport': (options) => {
      return (
        <span style={{color: 'var(--text-color)', userSelect: 'none', width: '120px', textAlign: 'center'}}>
                        {options.first} - {options.last} / {options.totalRecords}
        </span>
      )
    }
  };
  const productionItemsAddedActionBodyTemplate = (rowData) => {
    return (
      <React.Fragment>
        {rowData.status === 'scheduled' ? (
          <Button icon="pi pi-trash" className="p-button-sm  p-button-danger p-mr-2"
                  tooltip={"Törlés"}
                  onClick={() => {
                    setLoading(true);
                    ScheduleitemsProductionItemDeleteService(props.initialValues.id, rowData.id)
                      .then(response => {
                        window.App.setState({
                          rerenderManufacturingcalendarListComponent: true
                        })
                        ScheduleitemShowService(props.initialValues.id)
                          .then(response => {
                            setFormData(response.data)
                          })
                          .catch(error => {

                          })
                        productionItemsAddedLoadLazyData();
                        productionItemsToAddLoadLazyData();
                      })
                      .catch(error => {
                        setLoading(false)
                        window.App.toastShow('error', 'Hiba történt!', error.toString(), '', error.response);
                      })
                  }}/>
        ) : (
          <Button icon="pi pi-trash" className="p-button-sm  p-button-danger p-mr-2 disabled"
                  tooltip={"Törlés"}
          />

        )}
      </React.Fragment>
    );
  }

  // production items to add
  const pitadt = useRef(null);

  const [productionItemsToAdd, setProductionItemsToAdd] = useState([]);
  const [productionItemsToAddTotalRecords, setProductionItemsToAddTotalRecords] = useState(0);
  const [productionItemsToAddRows, setProductionItemsToAddRows] = useState(10);
  const [productionItemsToAddLazyParams, setProductionItemsToAddLazyParams] = useState({
    first: 0,
    rows: productionItemsToAddRows,
    page: 0,
    sortField: 'id',
    sortOrder: -1,
    filter: {
      status: {value: 'new'}
    }
  });
  let productionItemsToAddLoadLazyTimeout = null;
  const productionItemsToAddLoadLazyData = () => {
    setLoading(true);
    if (productionItemsToAddLoadLazyTimeout) {
      clearTimeout(productionItemsToAddLoadLazyTimeout);
    }
    productionItemsToAddLoadLazyTimeout = setTimeout(() => {
      ProductionitemListService(JSON.stringify(productionItemsToAddLazyParams))
        .then(response => {
          setProductionItemsToAddTotalRecords(response.data.total_item_count);
          setProductionItemsToAdd(response.data.items);
          setLoading(false);
        })
        .catch(error => {
          setLoading(false)
          window.App.toastShow('error', 'Hiba történt!', error.toString(), '', error.response);
        })
    }, Math.random() * 1000 + 250);
  }
  const productionItemsToAddOnPage = (event) => {
    let _productionItemsToAddLazyParams = {...productionItemsToAddLazyParams, ...event};
    setProductionItemsToAddLazyParams(_productionItemsToAddLazyParams);
  }
  const productionItemsToAddOnSort = (event) => {
    let _productionItemsToAddLazyParams = {...productionItemsToAddLazyParams, ...event};
    setProductionItemsToAddLazyParams(_productionItemsToAddLazyParams);
  }
  const productionItemsToAddOnFilter = (event) => {
    let _productionItemsToAddLazyParams = {...productionItemsToAddLazyParams, ...event};
    _productionItemsToAddLazyParams['first'] = 0;
    _productionItemsToAddLazyParams['page'] = 0;
    setProductionItemsToAddLazyParams(_productionItemsToAddLazyParams);
  }
  const productionItemsToAddPager = {
    layout: 'RowsPerPageDropdown CurrentPageReport PrevPageLink NextPageLink',
    'RowsPerPageDropdown': (options) => {
      const dropdownOptions = [
        {label: 10, value: 10},
        {label: 20, value: 20},
        {label: 50, value: 50},
        {label: 200, value: 200}
      ];

      return (
        <>
          {/*<span className="p-mx-1" style={{color: 'var(--text-color)', userSelect: 'none'}}>Tételek egy oldalon: </span>*/}
          <Dropdown value={options.value} options={dropdownOptions}
                    onChange={(e) => setProductionItemsToAddRows(e.target.value)}
                    appendTo={document.body}/>
        </>
      );
    },
    'CurrentPageReport': (options) => {
      return (
        <span style={{color: 'var(--text-color)', userSelect: 'none', width: '120px', textAlign: 'center'}}>
                        {options.first} - {options.last} / {options.totalRecords}
        </span>
      )
    }
  };
  const productionItemsToAddActionBodyTemplate = (rowData) => {
    return (
      <React.Fragment>
        <Button icon="pi pi-plus" className="p-button-sm  p-button-success p-mr-2"
                tooltip={"Hozzáadás"}
                onClick={() => {
                  if(rowData && !rowData.estimatedTime) {
                    window.App.setState({productionItemRowData: rowData})
                    setEstimatedTimeDialog(true)
                  } else {
                    setLoading(true);
                    ScheduleitemsProductionItemAddService(props.initialValues.id, rowData.id, Number(productionItemsAdded.length + 1), rowData.estimatedTime)
                      .then(response => {
                        window.App.setState({
                          rerenderManufacturingcalendarListComponent: true
                        })
                        ScheduleitemShowService(props.initialValues.id)
                          .then(response => {
                            setFormData(response.data)
                          })
                          .catch(error => {

                          })
                        productionItemsAddedLoadLazyData();
                        productionItemsToAddLoadLazyData();
                      })
                      .catch(error => {
                        setLoading(false)
                        window.App.toastShow('error', 'Hiba történt!', error.toString(), '', error.response);
                      })
                  }
                }}/>
      </React.Fragment>
    );
  }

  const [statuses, setStatuses] = useState([]);
  const status = (rowData) => {
    if (statuses && statuses[0]) {
      let status = statuses.filter(item => item.id === rowData.status)
      if (status[0] && status[0].name) {
        return status[0].name
      }
    }
  }

  useEffect(() => {
    ProductionitemStatusesService()
      .then(response => {
        setStatuses(response.data)
      })
      .catch(error => {

      })
    if (props && props.initialValues) {
      ScheduleitemShowService(props.initialValues.id)
        .then(response => {
          setFormData(response.data)
        })
        .catch(error => {

        })
    }
    productionItemsAddedLoadLazyData();
    productionItemsToAddLoadLazyData();
  }, [productionItemsAddedLazyParams, productionItemsToAddLazyParams])

  return (
    <>
      <h2 className={"text-primary p-mb-0"}>Műszak beosztás</h2>
      <div className={"p-grid"}>
        {formData && formData.employeeName &&
        <div className={"p-col"}>
          <small className={"p-text-uppercase"}>
            Munkavállaló
          </small>
          <p className={"p-mt-0 p-mb-0 p-text-bold"}>
            {formData.employeeName}
          </p>
        </div>
        }
        {formData && formData.dateFrom &&
        <div className={"p-col"}>
          <small className={"p-text-uppercase"}>
            Műszak időtartam
          </small>
          <p className={"p-mt-0 p-mb-0 p-text-bold"}>
            {moment.utc(formData.dateFrom).local().format('YYYY-MM-DD HH:mm')}
            &nbsp;-&nbsp;
            {moment.utc(formData.dateUntil).local().format('YYYY-MM-DD HH:mm')}
          </p>
        </div>
        }
        {formData && formData.scheduledLoad &&
        <div className={"p-col"}>
          <small className={"p-text-uppercase"}>
            Tervezett kihasználtság
          </small>
          <p className={"p-mt-0 p-mb-0 p-text-bold"}>
            <ProgressBar value={formData.scheduledLoad}></ProgressBar>
          </p>
        </div>
        }
        {formData && formData.producedLoad &&
        <div className={"p-col"}>
          <small className={"p-text-uppercase"}>
            Valós kihasználtság
          </small>
          <p className={"p-mt-0 p-mb-0 p-text-bold"}>
            <ProgressBar value={formData.producedLoad}></ProgressBar>
          </p>
        </div>
        }
      </div>
      <Panel headerTemplate={
        <div className={"p-grid"}>
          <div className={"p-col-12"}>
            <h3>
              <i className={"pi pi-list"}></i> Műszakhoz rendelt feladatok
            </h3>
          </div>
        </div>
      } className={""}>
        <div className={"p-grid"}>
          <div className={"p-col"}>
            <DataTable
              emptyMessage="Nincs találat."
              value={productionItemsAdded} paginator first={productionItemsAddedLazyParams.first}
              totalRecords={productionItemsAddedTotalRecords} onPage={productionItemsAddedOnPage}
              onSort={productionItemsAddedOnSort} sortField={productionItemsAddedLazyParams.sortField}
              sortOrder={productionItemsAddedLazyParams.sortOrder}
              onFilter={productionItemsAddedOnFilter} filters={productionItemsAddedLazyParams.filters} loading={loading}
              lazy
              paginatorTemplate={productionItemsAddedPager} rows={productionItemsAddedRows}
              emptyMessage="Nincs találat."
              autoLayout={true}
              filterDisplay="row"
              responsiveLayout="scroll"
              stripedRows
              dataKey="id"
            >
              <Column body={productionItemsAddedActionBodyTemplate} className={"p-py-0 p-text-nowrap"}></Column>
              <Column field="status" header="Státusz" sortable body={status}></Column>
              <Column field="serial" header="Sorszám" sortable filter filterPlaceholder="Sorszám"></Column>
              <Column field="name" header="Név" sortable filter filterPlaceholder="Név"></Column>
              <Column field="employeeName" header="Munkavállaló" sortable filter filterPlaceholder="Munkavállaló"></Column>
              <Column field="projectItemName" header="Tétel" sortable filter filterPlaceholder="Tétel"></Column>
              <Column field="technologyName" header="Technológia" sortable filter
                      filterPlaceholder="Technológia"></Column>
              <Column field="projectNumber" header="Projektszám" sortable filter
                      filterPlaceholder="Projektszám"></Column>
              <Column field="externalReference" header="Külső hivatkozás" sortable filter
                      filterPlaceholder="Külső hivatkozás"></Column>
              <Column field="projectName" header="Projekt" sortable filter filterPlaceholder="Projekt"></Column>
              <Column field="partnerName" header="Partner" sortable filter filterPlaceholder="Partner"></Column>
              <Column field="quantity" header="Mennyiség" className={"p-text-right"} body={(rowData) => {
                return (
                  <>
                    {rowData.quantity} {rowData.unitName}
                  </>
                )
              }}></Column>
            </DataTable>
          </div>
        </div>
      </Panel>

      <Panel headerTemplate={
        <div className={"p-grid"}>
          <div className={"p-col-12"}>
            <h3>
              <i className={"pi pi-list"}></i> Beosztásra váró feladatok
            </h3>
          </div>
        </div>
      } className={"p-mt-3"}>

        <div className={"p-grid"}>
          <div className={"p-col"}>
            <DataTable
              emptyMessage="Nincs találat."
              value={productionItemsToAdd} paginator first={productionItemsToAddLazyParams.first}
              totalRecords={productionItemsToAddTotalRecords} onPage={productionItemsToAddOnPage}
              onSort={productionItemsToAddOnSort} sortField={productionItemsToAddLazyParams.sortField}
              sortOrder={productionItemsToAddLazyParams.sortOrder}
              onFilter={productionItemsToAddOnFilter} filters={productionItemsToAddLazyParams.filters} loading={loading}
              lazy
              paginatorTemplate={productionItemsToAddPager} rows={productionItemsToAddRows}
              autoLayout={true}
              filterDisplay="row"
              responsiveLayout="scroll"
              stripedRows
              dataKey="id"
              ref={pitadt}
            >
              <Column body={productionItemsToAddActionBodyTemplate} className={"p-py-0 p-text-nowrap"}></Column>
              <Column field="status" header="Státusz" sortable filter body={status}
                      filterElement={() => {
                        return (
                          <>
                            {productionItemsToAddLazyParams && productionItemsToAddLazyParams.filters && productionItemsToAddLazyParams.filters.status ? (
                              <Dropdown options={statuses}
                                        id={"statuses"}
                                        optionValue={"id"}
                                        optionLabel={"name"}
                                        showClear
                                        emptyMessage={"Még nincs típus..."}
                                        onChange={(e) => {
                                          pitadt.current.filter(e.value, 'status', '');
                                        }}
                                        value={productionItemsToAddLazyParams.filters.status.value}
                              />
                            ) : (
                              <Dropdown options={statuses}
                                        id={"statuses"}
                                        optionValue={"id"}
                                        optionLabel={"name"}
                                        showClear
                                        emptyMessage={"Még nincs státusz..."}
                                        onChange={(e) => {
                                          pitadt.current.filter(e.value, 'status', '');
                                        }}
                              />
                            )}
                          </>
                        )
                      }}
              ></Column>
              <Column field="serial" header="Sorszám" sortable filter filterPlaceholder="Sorszám"></Column>
              <Column field="name" header="Név" sortable filter filterPlaceholder="Név"></Column>
              <Column field="projectItemName" header="Tétel" sortable filter filterPlaceholder="Tétel"></Column>
              <Column field="technologyName" header="Technológia" sortable filter
                      filterPlaceholder="Technológia"></Column>
              <Column field="projectNumber" header="Projektszám" sortable filter
                      filterPlaceholder="Projektszám"></Column>
              <Column field="externalReference" header="Külső hivatkozás" sortable filter
                      filterPlaceholder="Külső hivatkozás"></Column>
              <Column field="projectName" header="Projekt" sortable filter filterPlaceholder="Projekt"></Column>
              <Column field="partnerName" header="Partner" sortable filter filterPlaceholder="Partner"></Column>
              <Column field="quantity" header="Mennyiség" className={"p-text-right"} body={(rowData) => {
                return (
                  <>
                    {rowData.quantity} {rowData.unitName}
                  </>
                )
              }}></Column>
            </DataTable>
            <Dialog visible={estimatedTimeDialog}
                    style={{width: '750px'}}
                    header="Ráfordítás hozzáadása"
                    modal
                    onHide={() => {
                      setEstimatedTimeDialog(false)
                    }}>

              <div className={"p-grid"}>
                <div className={"p-col"}>
                  <div className="p-field p-fluid">
                    <label htmlFor="estimatedTime">Becsült időráfordítás (perc)</label>
                    <span className={"p-d-block"}>
                      <InputNumber id="estimatedTime"
                                   locale="hu-HU"
                                   onChange={(e) => {
                                     setEstimatedTime(e.value)
                                   }}
                                   value={estimatedTime}
                                   />
                            </span>
                  </div>
                </div>
                <div className={"p-col-12"}>
                  <Message severity="warn"
                           text="A feladat becsült teljes időráfordítása percben."></Message>
                </div>
              </div>

              <div className={"p-grid p-mt-3"}>
                <div className={"p-col-12 p-lg-6 p-xl-6"}>
                </div>
                <div className={"p-col-12 p-lg-6 p-xl-6 p-text-right"}>
                  <Button type="button" label="Hozzáadás" icon="pi pi-check"
                          onClick={() => {
                            setLoading(true);
                            ScheduleitemsProductionItemAddService(props.initialValues.id, window.App.state.productionItemRowData.id, Number(productionItemsAdded.length + 1), estimatedTime)
                              .then(response => {
                                window.App.setState({
                                  rerenderManufacturingcalendarListComponent: true
                                })
                                ScheduleitemShowService(props.initialValues.id)
                                  .then(response => {
                                    setFormData(response.data)
                                  })
                                  .catch(error => {

                                  })
                                productionItemsAddedLoadLazyData();
                                productionItemsToAddLoadLazyData();
                                setEstimatedTimeDialog(false)
                              })
                              .catch(error => {
                                setLoading(false)
                                window.App.toastShow('error', 'Hiba történt!', error.toString(), '', error.response);
                              })
                          }}
                          loading={loading}/>
                </div>
              </div>
            </Dialog>
          </div>
        </div>

      </Panel>
    </>
  )
}
export default withTranslation('common')(ScheduleitemscalendarListComponent);
