import React, {useState, useEffect} from 'react';
import {withTranslation} from 'react-i18next';
import {Form, Field} from 'react-final-form';
import {classNames} from 'primereact/utils';
import {InputText} from 'primereact/inputtext';
import {Button} from 'primereact/button';
import {Panel} from "primereact/panel";
import {Dropdown} from "primereact/dropdown";
import {Calendar} from "primereact/calendar";
import {addLocale} from "primereact/api";
import moment from "moment";
import {Message} from "primereact/message";

import EntrycheckService from "../../services/entrycheck/EntrycheckService";
import EntryterminalListService from "../../services/entryterminal/EntryterminalListService";
import {InputMask} from "primereact/inputmask";

const EntrycheckFormComponent = (props) => {

  const [formData, setFormData] = useState({});
  const [loading, setLoading] = useState(false);
  const [terminals, setTerminals] = useState([]);
  const [response, setResponse] = useState({});

  const validate = (data) => {
    let errors = {};
    // if (!data.terminal) {
    //   errors.terminal = 'Az terminál nem lehet üres';
    // }
    // if (!data.identifier) {
    //   errors.identifier = 'Az azonosító nem lehet üres';
    // }
    // if (!data.direction) {
    //   errors.direction = 'Az irány nem lehet üres';
    // }
    // if (!data.checkDate) {
    //   errors.checkDate = 'Az időpont nem lehet üres';
    // }
    return errors;
  };
  const onSubmit = (data, form) => {
    setLoading(true)
    setFormData({
      ...formData,
      data
    });
    EntrycheckService(data.terminal, data.identifier, data.direction, moment.utc(data.checkDate).local().format('YYYY-MM-DD'))
      .then(response => {
        if (response.status === 200) {
          setResponse({
            message: 'Áthaladás engedélyezve!',
            severity: 'success'
          })
        }
        setLoading(false)
      })
      .catch(error => {
        if (error.response.status === 409) {
          setResponse({
            message: 'Áthaladás megtagadva!',
            severity: 'error'
          })
        }
        if (error.response.status === 403) {
          setResponse({
            message: 'Ismeretlen azonosító!',
            severity: 'warn'
          })
        }
        if (error.response.status === 404) {
          setResponse({
            message: 'Nem található terminál!',
            severity: 'warn'
          })
        }
        if (error.response.status === 500) {
          setResponse({
            message: 'Hiba történt!',
            severity: 'warn'
          })
        }
        setLoading(false)
      })
    form.restart();
  };
  const isFormFieldValid = (meta) => !!(meta.touched && meta.error);
  const getFormErrorMessage = (meta) => {
    return isFormFieldValid(meta) && <small className="p-error">{meta.error}</small>;
  };

  addLocale('hu', {
    firstDayOfWeek: 1,
    dayNames: ['vasárnap', 'hétfő', 'kedd', 'szerda', 'csütörtök', 'péntek', 'szombat'],
    dayNamesShort: ['vas', 'hé', 'ke', 'sze', 'csü', 'pé', 'szo'],
    dayNamesMin: ['V', 'H', 'K', 'SZ', 'CS', 'P', 'SZ'],
    monthNames: ['január', 'február', 'március', 'április', 'május', 'június', 'július', 'augusztus', 'szeptember',
      'október', 'november', 'december'],
    monthNamesShort: ['jan', 'feb', 'mar', 'ápr', 'máj', 'jun', 'júl', 'aug', 'szep', 'okt', 'nov', 'dec'],
    today: 'Ma',
    clear: 'Töröl'
  });

  useEffect(() => {
    EntryterminalListService(JSON.stringify({
      first: 0,
      rows: 9999,
      page: 0,
    }))
      .then(response => {
        setTerminals(response.data.items)
      })
      .catch(error => {
        window.App.toastShow('error', 'Hiba történt!', error.toString(), '', error.response);
      })
    if (props && props.identifier) {
      setFormData({
        direction: 'in',
        identifier: props.identifier
      })
    }
  }, [])

  return (
    <Form onSubmit={onSubmit} initialValues={formData}
          validate={validate} render={({handleSubmit}) => (
      <form onSubmit={handleSubmit} className="">
        <div className={"p-grid"}>
          <div className={"p-col-12 p-md-3 p-lg-3"}>
            <Field name="name" render={({input, meta}) => (
              <div className="p-field p-fluid">
                <label htmlFor="name"
                       className={classNames({'p-error': isFormFieldValid(meta)})}>Azonosító</label>
                <span className="p-d-block">
                      <InputText id="identifier" {...input}
                                 onChange={(e) => {
                                   setFormData({
                                     ...formData,
                                     identifier: e.target.value
                                   })
                                 }}
                                 value={formData.identifier}
                                 className={classNames({'p-invalid': isFormFieldValid(meta)})}/>
                    </span>
                {getFormErrorMessage(meta)}
              </div>
            )}/>
          </div>
          <div className={"p-col-12 p-md-3 p-lg-3"}>
            <Field name="terminal" render={({input, meta}) => (
              <div className="p-field p-fluid">
                <label htmlFor="terminal"
                       className={classNames({'p-error': isFormFieldValid(meta)})}>Terminál</label>
                <span className="p-d-block">
                    {formData && formData.terminal ? (
                      <Dropdown options={terminals}
                                optionLabel="name"
                                optionValue="identifier"
                                id={"terminal"}
                                emptyMessage={"Még nincs terminál..."}
                                onChange={(e) => {
                                  setFormData({
                                    ...formData,
                                    terminal: e.value
                                  })
                                }}
                                value={formData.terminal}
                                showClear
                      />
                    ) : (
                      <Dropdown options={terminals}
                                optionLabel="name"
                                optionValue="identifier"
                                id={"terminal"}
                                emptyMessage={"Még nincs terminál..."}
                                onChange={(e) => {
                                  setFormData({
                                    ...formData,
                                    terminal: e.value
                                  })
                                }}
                                showClear
                      />
                    )}
                  </span>
                {getFormErrorMessage(meta)}
              </div>
            )}/>
          </div>
          <div className={"p-col-12 p-lg-3 p-xl-3"}>
            <Field name="checkDate" render={({input, meta}) => (
              <div className="p-field p-fluid">
                <label htmlFor="checkDate"
                       className={classNames({'p-error': isFormFieldValid(meta)})}>Időpont</label>
                <span className={"p-d-block"}>
                  <InputMask id={"checkDate"}
                             mask={"9999-99-99"}
                             placeholder={"ÉÉÉÉ-HH-NN"}
                             value={formData.checkDate}
                             onChange={(e) => {
                               if (e.value.indexOf("_") === -1) {
                                 if (e.value === '') {
                                   e.value = null
                                 }
                                 setFormData({
                                   ...formData,
                                   checkDate: e.value
                                 })
                               }
                             }}
                  />
                    </span>
                {getFormErrorMessage(meta)}
              </div>
            )}/>
          </div>
          <div className={"p-col-12 p-md-3 p-lg-3"}>
            <Field name="direction" render={({input, meta}) => (
              <div className="p-field p-fluid">
                <label htmlFor="direction"
                       className={classNames({'p-error': isFormFieldValid(meta)})}>Irány</label>
                <span className={"p-d-block"}>
                      <Dropdown options={[
                        {
                          name: 'ki',
                          value: 'out',
                        },
                        {
                          name: 'be',
                          value: 'in',
                        },
                      ]}
                                optionLabel="name"
                                optionValue="value"
                                id={"direction"}
                                onChange={(e) => {
                                  setFormData({
                                    ...formData,
                                    direction: e.value
                                  })
                                }}
                                value={formData.direction}
                                showClear
                      />
                  </span>
                {getFormErrorMessage(meta)}
              </div>
            )}/>
          </div>

        </div>
        <div className={"p-grid p-mt-3"}>
          <div className={"p-col-12 p-lg-6 p-xl-6"}>
            {response && response.message &&
            <Message severity={response.severity} text={response.message}/>
            }
          </div>
          <div className={"p-col-12 p-lg-6 p-xl-6 p-text-right"}>
            <Button type="submit" label="Teszt indítása" icon={"pi pi-check"} loading={loading}
                    className="p-button-success"/>
          </div>
        </div>
      </form>
    )}/>
  )
}
export default withTranslation('common')(EntrycheckFormComponent);
