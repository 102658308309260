import React, {useState, useEffect} from 'react';
import {withTranslation} from 'react-i18next';
import {Panel} from "primereact/panel";
import {Timeline} from "primereact/timeline";
import {Card} from "primereact/card";
import moment from "moment";
import {Column} from "primereact/column";
import {DataTable} from "primereact/datatable";
import {Dialog} from "primereact/dialog";
import {Button} from "primereact/button";
import download from "js-file-download";
import {Form, Field} from 'react-final-form';
import {classNames} from "primereact/utils";
import {Dropdown} from "primereact/dropdown";
import {AutoComplete} from "primereact/autocomplete";

import EntrylocationShowService from "../../services/entrylocation/EntrylocationShowService";
import EntrylocationListCompanyService from "../../services/entrylocation/EntrylocationListCompanyService";
import EntrylocationSubcontractorInviteComponent from "./EntrylocationSubcontractorInviteComponent";
import EntrylocationOwnerInviteComponent from "./EntrylocationOwnerInviteComponent";
import EntrylocationContactListService from "../../services/entrylocation/EntrylocationContactListService";
import EntrylocationCompanyDocumentListService
  from "../../services/entrylocation/EntrylocationCompanyDocumentListService";
import EntrylocationDeniedEmployeesListService
  from "../../services/entrylocation/EntrylocationDeniedEmployeesListService";
import EntrylocationDeniedEmployeesDeleteService
  from "../../services/entrylocation/EntrylocationDeniedEmployeesDeleteService";
import EntrylocationDeniedEmployeesService from "../../services/entrylocation/EntrylocationDeniedEmployeesService";
import EntrylocationemployeeListService from "../../services/entrylocationemployee/EntrylocationemployeeListService";

const EntrylocationShowComponent = (props) => {

  const [formData, setFormData] = useState({});
  const [loadingDeniedEmployees, setLoadingDeniedEmployees] = useState(false);
  const [companyFormData, setCompanyFormData] = useState({}); // set on dialog open!
  const [deniedEmployeesFormData, setDeniedEmployeesFormData] = useState({}); // set on dialog open!

  const companyActionBodyTemplate = (rowData) => {
    return (
      <React.Fragment>
        {localStorage.getItem('roles').toString().includes('role_entry_location_subcontractor_invite') &&
        <Button icon="pi pi-envelope" className="p-button-sm p-button-info p-mr-2"
                type="button"
                onClick={() => {
                  window.App.setState({dialogEntrylocationSubcontractor: true})
                  setCompanyFormData({
                    ...companyFormData,
                    companyId: rowData.id
                  })
                  window.App.setState({props: formData.id})
                }}/>
        }
      </React.Fragment>
    );
  }

  const deniedEmployeeActionBodyTemplate = (rowData) => {
    return (
      <React.Fragment>
        <Button icon="pi pi-trash" className="p-button-sm  p-button-danger p-mr-2"
                type="button"
                onClick={() => {
                  let data = {
                    employee: {
                      id: rowData.id
                    }
                  }
                  EntrylocationDeniedEmployeesDeleteService(data)
                    .then(response => {
                      window.App.toastShow('success', 'Sikeres törlés!');
                      EntrylocationDeniedEmployeesListService()
                        .then(response => {
                          window.App.setState({dataEntrylocationDeniedEmployees: response.data.items})
                        })
                        .catch(error => {
                          window.App.toastShow('error', 'Hiba történt!', error.toString(), '', error.response);
                        })
                    })
                    .catch(error => {
                      window.App.toastShow('error', 'Hiba történt!', error.toString(), '', error.response);
                    })
                }}
        />
      </React.Fragment>
    );
  }

  //denied employees
  const deniedEmployeesValidate = (data) => {
    let errors = {};
    if (!data.employee) {
      errors.employee = 'Kötelező mező';
    }
    return errors;
  };

  const deniedEmployeeOnSubmit = (data, form) => {
    setLoadingDeniedEmployees(true)
    setFormData({
      ...formData,
      data
    });
    EntrylocationDeniedEmployeesService(data)
      .then(response => {
        window.App.toastShow('success', 'Sikeres kitiltás!');
        window.App.setState({
          dialogEntrylocationDeniedEmployee: false
        })
        EntrylocationDeniedEmployeesListService()
          .then(response => {
            window.App.setState({dataEntrylocationDeniedEmployees: response.data.items})
          })
          .catch(error => {
            window.App.toastShow('error', 'Hiba történt!', error.toString(), '', error.response);
          })
        setLoadingDeniedEmployees(false)
      })
      .catch(error => {
        window.App.toastShow('error', 'Hiba történt!', error.toString(), '', error.response);
        setLoadingDeniedEmployees(false)
      })
  }

  const isFormFieldValid = (meta) => !!(meta.touched && meta.error);
  const getFormErrorMessage = (meta) => {
    return isFormFieldValid(meta) && <small className="p-error">{meta.error}</small>;
  };

  // employees autocomplete
  const [employees, setEmployees] = useState([]);
  const [selectedEmployee, setSelectedEmployee] = useState('');

  const searchEmployees = (event) => {
    EntrylocationemployeeListService(JSON.stringify({filters: {name: {value: event.query}}}))
      .then(response => {
        if (response.data && response.data.items && response.data.items[0]) {
          let _items = []
          Object.entries(response.data.items).map(function (item, index) {
            _items.push({
              name: item[1].name + " (" + item[1].companyName + ")",
              id: item[1].id
            })
          })
          setEmployees(_items)
        }
      })
      .catch(error => {
        window.App.toastShow('error', 'Hiba történt!', error.toString(), '', error.response);
      })

  }

  // pager
  const [rows, setRows] = useState(10);
  const pager = {
    layout: 'RowsPerPageDropdown CurrentPageReport PrevPageLink NextPageLink',
    'RowsPerPageDropdown': (options) => {
      const dropdownOptions = [
        {label: 10, value: 10},
        {label: 20, value: 20},
        {label: 50, value: 50},
        {label: 200, value: 200}
      ];

      return (
        <>
          {/*<span className="p-mx-1" style={{color: 'var(--text-color)', userSelect: 'none'}}>Tételek egy oldalon: </span>*/}
          <Dropdown value={options.value} options={dropdownOptions} onChange={(e) => setRows(e.target.value)}
                    appendTo={document.body}/>
        </>
      );
    },
    'CurrentPageReport': (options) => {
      return (
        <span style={{color: 'var(--text-color)', userSelect: 'none', width: '120px', textAlign: 'center'}}>
                        {options.first} - {options.last} / {options.totalRecords}
        </span>
      )
    }
  };

  useEffect(() => {
    if (props && props.initialValues) {
      EntrylocationShowService(props.initialValues.id)
        .then(response => {
          setFormData(response.data)
          EntrylocationListCompanyService(response.data.id,
            JSON.stringify({
              first: 0,
              rows: 9999,
              page: 0,
            })
          )
            .then(response => {
              window.App.setState({dataEntrylocationSubcontractors: response.data.items})
            })
            .catch(error => {
              window.App.toastShow('error', 'Hiba történt!', error.toString(), '', error.response);
            })
          EntrylocationContactListService(JSON.stringify({
            first: 0,
            rows: 9999,
            page: 0,
          }), response.data.id)
            .then(response => {
              window.App.setState({dataEntrylocationContacts: response.data.items})
            })
            .catch(error => {
              window.App.toastShow('error', 'Hiba történt!', error.toString(), '', error.response);
            })
          EntrylocationCompanyDocumentListService(JSON.stringify({
            first: 0,
            rows: 9999,
            page: 0,
          }), response.data.id)
            .then(response => {
              window.App.setState({dataEntrylocationCompanyDocuments: response.data.items})
            })
            .catch(error => {
              window.App.toastShow('error', 'Hiba történt!', error.toString(), '', error.response);
            })
          EntrylocationemployeeListService()
            .then(response => {
              window.App.setState({dataEntrylocationCurrentEmployees: response.data.items})
            })
            .catch(error => {
              window.App.toastShow('error', 'Hiba történt!', error.toString(), '', error.response);
            })
          EntrylocationDeniedEmployeesListService(
            JSON.stringify({
              first: 0,
              rows: 9999,
              page: 0,
            })
          )
            .then(response => {
              window.App.setState({dataEntrylocationDeniedEmployees: response.data.items})
            })
            .catch(error => {
              window.App.toastShow('error', 'Hiba történt!', error.toString(), '', error.response);
            })
        })
        .catch(error => {
          window.App.toastShow('error', 'Hiba történt!', error.toString(), '', error.response);
        })
    }
  }, [])

  return (
    <div className={"p-grid"}>
      <div className={"p-col-12 p-lg-12 p-xl-12"}>
        <Panel headerTemplate={
          <div className={"p-grid"}>
            <div className={"p-col-12"}>
              <h3>
                <i className={"pi pi-list"}></i> Alapadatok
              </h3>
            </div>
          </div>
        } className={""}>
          <div className={"p-grid"}>
            {localStorage.getItem('roles').toString().includes('role_entry_location_read') &&
            <>
              {formData.name &&
              <div className={"p-col-12 p-md-6 p-lg-6"}>
                <label>Megnevezés</label>
                <p className={"p-mt-1 p-mb-0 p-text-bold"}>
                  {formData.name}
                </p>
              </div>
              }
              {formData.companyName &&
              <div className={"p-col-12 p-md-4 p-lg-4"}>
                <label>Cég</label>
                <p className={"p-mt-1 p-mb-0 p-text-bold"}>
                  {formData.companyName}
                </p>
              </div>
              }
              {formData.contactName &&
              <div className={"p-col-12 p-md-2 p-lg-2"}>
                <label>Kapcsolattartó</label>
                <p className={"p-mt-1 p-mb-0 p-text-bold"}>
                  {formData.contactName}
                </p>
              </div>
              }
              {formData.active &&
              <div className={"p-col-12 p-md-2 p-lg-2"}>
                <label>Aktív</label>
                <p className={"p-mt-1 p-mb-0 p-text-bold"}>
                  {formData.active === true ? (
                    <span className={"text-success"}><i className={"pi pi-check"}></i> Igen</span>
                  ) : (
                    <span className={"text-danger"}><i className={"pi pi-times"}></i> Nem</span>
                  )}
                </p>
              </div>
              }
              <div className={"p-col-12 p-md-2 p-lg-2"}>
                <label>Üvegkapu</label>
                <p className={"p-mt-1 p-mb-0 p-text-bold"}>
                  {formData.supervised && formData.supervised === true ? (
                    <span className={"text-success"}><i className={"pi pi-check"}></i> Igen</span>
                  ) : (
                    <span className={"text-danger"}><i className={"pi pi-times"}></i> Nem</span>
                  )}
                </p>
              </div>
            </>
            }
            {formData.description && localStorage.getItem('roles').toString().includes('role_entry_location_description') &&
            <div className={"p-col-12"}>
              <label>Leírás</label>
              <div className={"p-mt-1 p-mb-0 p-text-bold"} dangerouslySetInnerHTML={{__html: formData.description}}>

              </div>
            </div>
            }
          </div>
          <div className={"p-grid p-mt-3"}>
            <div className={"p-col-12 p-lg-6 p-xl-6"}>
              {localStorage.getItem('roles').toString().includes('role_entry_location_invite') &&
              <Button type="button" label="Meghívás" icon="pi pi-envelope"
                      className="p-button-info p-button-highlighted"
                      onClick={() => {
                        window.App.setState({dialogEntrylocationOwner: true})
                        setCompanyFormData({
                          ...companyFormData,
                          companyId: formData.id
                        })
                        window.App.setState({props: formData.id})
                      }}

              />
              }
            </div>
            <div className={"p-col-12 p-lg-6 p-xl-6 p-text-right"}>
              {formData.id && localStorage.getItem('roles').toString().includes('role_entry_location_update') &&
              <Button type="button" label="Módosítás" icon="pi pi-pencil" className="p-button-warning"
                      onClick={() => {
                        window.App.setState({
                          sidebarEntrylocationShow: false,
                          sidebarEntrylocationUpdate: true,
                          props: formData
                        })
                      }}
              />
              }
            </div>
          </div>
        </Panel>
        <Panel headerTemplate={
          <div className={"p-grid"}>
            <div className={"p-col-12"}>
              <h3>
                <i className={"pi pi-list"}></i> Közreműködők
              </h3>
            </div>
          </div>
        } className={"p-mt-3"}>
          <DataTable value={window.App.state.dataEntrylocationSubcontractors}
                     emptyMessage="Nincs találat."
                     autoLayout={true}
                     filterDisplay="row"
                     responsiveLayout="scroll"
                     stripedRows
                     paginator
                     paginatorTemplate={pager}
                     rows={rows}
          >
            {/*<Column body={companyActionBodyTemplate} className={"p-py-0 p-text-nowrap"}></Column>*/}
            <Column field="companyName" header="Név"></Column>
          </DataTable>
          <div className={"p-grid p-mt-3"}>
            <div className={"p-col-12 p-lg-6 p-xl-6"}>

            </div>
            <div className={"p-col-12 p-lg-6 p-xl-6 p-text-right"}>
              {localStorage.getItem('roles').toString().includes('role_entry_location_subcontractor_invite') &&
              <Button type="button" label="Meghívás" icon="pi pi-envelope"
                      className="p-button-info  p-button-highlighted"
                      onClick={() => {
                        window.App.setState({dialogEntrylocationSubcontractor: true})
                        setCompanyFormData({
                          ...companyFormData,
                          companyId: formData.id
                        })
                        window.App.setState({props: formData.id})
                      }}
              />
              }
            </div>
          </div>
        </Panel>
        {localStorage.getItem('roles').toString().includes('role_work_type_create') &&
        <Panel headerTemplate={
          <div className={"p-grid"}>
            <div className={"p-col-12"}>
              <h3>
                <i className={"pi pi-list"}></i> Kitiltott munkavállalók
              </h3>
            </div>
          </div>
        } className={"p-mt-3"}>
          <DataTable value={window.App.state.dataEntrylocationDeniedEmployees}
                     emptyMessage="Nincs találat."
                     autoLayout={true}
                     filterDisplay="row"
                     responsiveLayout="scroll"
                     stripedRows
                     paginator
                     paginatorTemplate={pager}
                     rows={rows}
          >
            <Column body={deniedEmployeeActionBodyTemplate} className={"p-py-0 p-text-nowrap"}></Column>
            <Column field="name" header="Név"></Column>
            <Column field="companyName" header="Vállalkozás"></Column>
          </DataTable>
          <div className={"p-grid p-mt-3"}>
            <div className={"p-col-12 p-lg-6 p-xl-6"}>

            </div>
            <div className={"p-col-12 p-lg-6 p-xl-6 p-text-right"}>
              <Button type="button" label="Új munkavállaló kitiltása" icon="pi pi-times"
                      className="p-button-danger p-button-highlighted"
                      onClick={() => {
                        window.App.setState({dialogEntrylocationDeniedEmployee: true})
                      }}
              />
            </div>
          </div>
        </Panel>
        }
        {localStorage.getItem('roles').toString().includes('role_entry_location_read') &&
        <Panel headerTemplate={
          <div className={"p-grid"}>
            <div className={"p-col-12"}>
              <h3>
                <i className={"pi pi-list"}></i> Kapcsolattartók
              </h3>
            </div>
          </div>
        } className={"p-mt-3"}>
          <DataTable value={window.App.state.dataEntrylocationContacts}
                     emptyMessage="Nincs találat."
                     autoLayout={true}
                     filterDisplay="row"
                     responsiveLayout="scroll"
                     stripedRows
                     paginator
                     paginatorTemplate={pager}
                     rows={rows}
          >
            <Column field="name" header="Név"></Column>
            <Column field="companyName" header="Cégnév"></Column>
            <Column field="phone" header="Telefon"></Column>
            <Column field="email" header="Email"></Column>
          </DataTable>
        </Panel>
        }
        {localStorage.getItem('roles').toString().includes('role_entry_location_description') &&
        <Panel headerTemplate={
          <div className={"p-grid"}>
            <div className={"p-col-12"}>
              <h3>
                <i className={"pi pi-list"}></i> Vállalkozások dokumentumai
              </h3>
            </div>
          </div>
        } className={"p-mt-3"}>
          <DataTable value={window.App.state.dataEntrylocationCompanyDocuments}
                     emptyMessage="Nincs találat."
                     autoLayout={true}
                     filterDisplay="row"
                     responsiveLayout="scroll"
                     stripedRows
                     paginator
                     paginatorTemplate={pager}
                     rows={rows}
          >
            <Column field="name" header="Megnevezés"></Column>
            <Column field="companyName" header="Cégnév"></Column>
            <Column field="filePath" header="Fájl" body={(rowData) => {
              return (
                <>
                  <a
                    href={process.env.REACT_APP_API_HOST + "/companyDocument/entryLocationDocumentDownload/" + rowData.id
                    + "?token=" + localStorage.getItem('userToken') + "."
                    + localStorage.getItem("networkId")}
                    target="_blank"
                  >
                    <i className={"pi pi-file"}></i>&nbsp;{rowData.originalFileName}
                  </a>
                </>
              )
            }}></Column>
          </DataTable>
        </Panel>
        }
        <Dialog visible={window.App.state.dialogEntrylocationDeniedEmployee}
                style={{width: '750px'}}
                header="Munkavállaló kitiltása"
                modal
                onHide={() => {
                  window.App.setState({dialogEntrylocationDeniedEmployee: false})
                }}>
          <Form onSubmit={deniedEmployeeOnSubmit}
                initialValues={deniedEmployeesFormData}
                validate={deniedEmployeesValidate}
                render={({handleSubmit}) => (
                  <form onSubmit={handleSubmit} className="">
                    <div className={"p-grid"}>
                      <div className={"p-col-12 p-md-12 p-lg-12"}>
                        <Field name="employee" render={({input, meta}) => (
                          <div className="p-field p-fluid">
                            <label htmlFor="employee"
                                   className={classNames({'p-error': isFormFieldValid(meta)})}>Munkavállaló</label>
                            <span className={"p-d-block"}>
                              <AutoComplete value={selectedEmployee}
                                            id={"employee"}
                                            suggestions={employees}
                                            forceSelection
                                            completeMethod={searchEmployees}
                                            field={"name"}
                                            delay="1000"
                                            dropdown
                                            placeholder={"Keresés gépeléssel..."}
                                            className={classNames({'p-invalid': isFormFieldValid(meta)})}
                                            onSelect={(e) => {
                                              setDeniedEmployeesFormData({
                                                ...deniedEmployeesFormData,
                                                employee: {id: e.value.id}
                                              })
                                            }}
                                            onChange={(e) => {
                                              setDeniedEmployeesFormData({
                                                ...deniedEmployeesFormData,
                                                employee: {id: null}
                                              })
                                              setSelectedEmployee(e.value)
                                            }}
                                            onClear={(e) => {
                                              setSelectedEmployee('')
                                            }}
                              />
                              {/*{deniedEmployeesFormData.employee && deniedEmployeesFormData.employee.id ? (*/}
                              {/*  <Dropdown options={window.App.state.dataEntrylocationCurrentEmployees}*/}
                              {/*            optionLabel="name"*/}
                              {/*            optionValue="id"*/}
                              {/*            id={"employee"}*/}
                              {/*            emptyMessage={"Még nincs munkavállaló..."}*/}
                              {/*            onChange={(e) => {*/}
                              {/*              setDeniedEmployeesFormData({*/}
                              {/*                ...deniedEmployeesFormData,*/}
                              {/*                employee: {id: e.value}*/}
                              {/*              })*/}
                              {/*            }}*/}
                              {/*            value={deniedEmployeesFormData.employee.id}*/}
                              {/*  />*/}
                              {/*) : (*/}
                              {/*  <Dropdown options={window.App.state.dataEntrylocationCurrentEmployees}*/}
                              {/*            optionLabel="name"*/}
                              {/*            optionValue="id"*/}
                              {/*            id={"employee"}*/}
                              {/*            emptyMessage={"Még nincs munkavállaló..."}*/}
                              {/*            onChange={(e) => {*/}
                              {/*              setDeniedEmployeesFormData({*/}
                              {/*                ...deniedEmployeesFormData,*/}
                              {/*                employee: {id: e.value}*/}
                              {/*              })*/}
                              {/*            }}*/}
                              {/*  />*/}
                              {/*)}*/}
                            </span>
                            {getFormErrorMessage(meta)}
                          </div>
                        )}/>
                      </div>
                    </div>
                    <div className={"p-grid p-mt-3"}>
                      <div className={"p-col-12 p-lg-6 p-xl-6"}>
                      </div>
                      <div className={"p-col-12 p-lg-6 p-xl-6 p-text-right"}>
                        <Button type="submit" label="Kitiltás" icon="pi pi-check" loading={loadingDeniedEmployees}/>
                      </div>
                    </div>
                  </form>
                )}/>
        </Dialog>
        <Dialog visible={window.App.state.dialogEntrylocationOwner}
                style={{width: '750px'}}
                header="Fővállalkozó meghívása"
                modal
                onHide={() => {
                  window.App.setState({dialogEntrylocationOwner: false})
                }}>
          <EntrylocationOwnerInviteComponent/>
        </Dialog>
        <Dialog visible={window.App.state.dialogEntrylocationSubcontractor}
                style={{width: '750px'}}
                header="Közreműködő meghívása"
                modal
                onHide={() => {
                  window.App.setState({dialogEntrylocationSubcontractor: false})
                  EntrylocationListCompanyService(formData.id)
                    .then(response => {
                      window.App.setState({dataEntrylocationSubcontractors: response.data.items})
                    })
                    .catch(error => {
                      window.App.toastShow('error', 'Hiba történt!', error.toString(), '', error.response);
                    })
                }}>
          <EntrylocationSubcontractorInviteComponent initialValues={formData.id}/>
        </Dialog>
      </div>
    </div>
  )
}
export default withTranslation('common')(EntrylocationShowComponent);
