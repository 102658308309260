import React, {useState, useEffect} from 'react';
import {withTranslation} from 'react-i18next';
import {Field, Form} from "react-final-form";
import {classNames} from "primereact/utils";
import {InputText} from "primereact/inputtext";
import {Button} from "primereact/button";
import {AutoComplete} from "primereact/autocomplete";
import {Panel} from "primereact/panel";

import EntrylocationInviteSubcontractorService
  from "../../services/entrylocation/EntrylocationInviteSubcontractorService";
import EntrylocationInviteOwnerService from "../../services/entrylocation/EntrylocationInviteOwnerService";
import CompanyListService from "../../services/company/CompanyListService";
import EntrylocationListCompanyService from "../../services/entrylocation/EntrylocationListCompanyService";
import {Dropdown} from "primereact/dropdown";
import EntrylocationInviteSubcontractorByNetworkService
  from "../../services/entrylocation/EntrylocationInviteSubcontractorByNetworkService";

const EntrylocationSubcontractorInviteComponent = (props) => {

  const [formData, setFormData] = useState({});
  const [loading, setLoading] = useState(false);
  const [companies, setCompanies] = useState([]);
  const [selectedCompany, setSelectedCompany] = useState(localStorage.getItem('defaultCompanyName'));

  const validate = (data) => {
    let errors = {};
    // if (!data.company) {
    //   errors.company = 'Kötelező mező';
    // }
    // if (!data.name) {
    //   errors.name = 'Kötelező mező';
    // }
    // if (!data.vatNumber) {
    //   errors.vatNumber = 'Kötelező mező';
    // }
    // if (!data.contactLastName) {
    //   errors.contactLastName = 'Kötelező mező';
    // }
    // if (!data.contactFirstName) {
    //   errors.contactFirstName = 'Kötelező mező';
    // }
    // if (!data.email) {
    //   errors.email = 'Az email mező nem lehet üres.';
    // } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(data.email)) {
    //   errors.email = 'Mindenképpen email formátumot adj meg! Pl. example@email.com';
    // }
    return errors;
  };


  const onSubmit = (data, form) => {
    setLoading(true)
    if (props && props.byNetwork && props.byNetwork === true) {
      EntrylocationInviteSubcontractorByNetworkService(data)
        .then(response => {
          window.App.toastShow('success', 'Sikeres meghívó küldés!');
          window.App.setState({
            dialogEntrylocationSubcontractor: false,
            sidebarEntrylocationSubcontractorInvite: false,
          })
          EntrylocationListCompanyService(response.data.id)
            .then(response => {
              window.App.setState({dataEntrylocationSubcontractors: response.data.items})
            })
            .catch(error => {
              window.App.toastShow('error', 'Hiba történt!', error.toString(), '', error.response);
            })
          setLoading(false)
        })
        .catch(error => {
          window.App.toastShow('error', 'Hiba történt!', error.toString(), '', error.response);
          setLoading(false)
        })
    } else {
      EntrylocationInviteSubcontractorService(data, props.initialValues)
        .then(response => {
          window.App.toastShow('success', 'Sikeres meghívó küldés!');
          window.App.setState({
            dialogEntrylocationSubcontractor: false,
            sidebarEntrylocationSubcontractorInvite: false,
          })
          EntrylocationListCompanyService(response.data.id)
            .then(response => {
              window.App.setState({dataEntrylocationSubcontractors: response.data.items})
            })
            .catch(error => {
              window.App.toastShow('error', 'Hiba történt!', error.toString(), '', error.response);
            })
          setLoading(false)
        })
        .catch(error => {
          window.App.toastShow('error', 'Hiba történt!', error.toString(), '', error.response);
          setLoading(false)
        })
    }

  }

  const isFormFieldValid = (meta) => !!(meta.touched && meta.error);
  const getFormErrorMessage = (meta) => {
    return isFormFieldValid(meta) && <small className="p-error">{meta.error}</small>;
  };

  const searchCompany = (event) => {
    CompanyListService(JSON.stringify({filters: {name: {value: event.query}}}))
      .then(response => {
        setCompanies(response.data.items)
      })
      .catch(error => {
        window.App.toastShow('error', 'Hiba történt!', error.toString(), '', error.response);
      })
  }

  useEffect(() => {
    CompanyListService()
      .then(response => {
        setCompanies(response.data.items)
      })
      .catch(error => {
        window.App.toastShow('error', 'Hiba történt!', error.toString(), '', error.response);
      })
    if (localStorage.getItem('defaultCompanyId')) {
      setFormData({
        ...formData,
        company: {
          id: Number(localStorage.getItem('defaultCompanyId'))
        }
      })
    }
  }, [])

  return (
    <Panel>

      <Form onSubmit={onSubmit}
            initialValues={formData}
            validate={validate}
            render={({handleSubmit}) => (
              <form onSubmit={handleSubmit} className="">
                <div className={"p-grid"}>
                  <div className={"p-col-12"}>
                    <h3>
                      <i className={"pi pi-map-marker"}></i> Meghívót küldő vállalkozás
                    </h3>
                  </div>
                </div>
                <div className={"p-grid"}>
                  <div className={"p-col-12 p-lg-6 p-xl-6"}>
                    <Field name="company" render={({input, meta}) => (
                      <div className="p-field p-fluid">
                        <label htmlFor="company"
                               className={classNames({'p-error': isFormFieldValid(meta)})}>Cég</label>
                        <span className={"p-d-block"}>
                                            {localStorage.getItem('roles').toString().includes('role_company_browse')
                                            || localStorage.getItem('roles').toString().includes('role_company_read') ? (

                                              <AutoComplete value={selectedCompany}
                                                            id={"company"}
                                                            suggestions={companies}
                                                            forceSelection
                                                            completeMethod={searchCompany}
                                                            field={"name"}
                                                            delay="1000"
                                                            placeholder={"Keresés gépeléssel..."}
                                                            className={classNames({'p-invalid': isFormFieldValid(meta)})}
                                                            onSelect={(e) => {
                                                              setFormData({
                                                                ...formData,
                                                                company: {id: e.value.id}
                                                              })
                                                              localStorage.setItem('defaultCompanyId', e.value.id)
                                                              localStorage.setItem('defaultCompanyName', e.value.name)
                                                            }}
                                                            onChange={(e) => {
                                                              setFormData({
                                                                ...formData,
                                                                company: {id: null}
                                                              })
                                                              setSelectedCompany(e.value)
                                                            }}
                                                            dropdown
                                                            onClear={(e) => {
                                                              setSelectedCompany('')
                                                            }}
                                              />
                                            ) : (
                                              <Dropdown disabled className={"disabled"} id={"company"}/>
                                            )}
                  </span>
                        {getFormErrorMessage(meta)}
                      </div>
                    )}/>
                  </div>
                </div>
                <div className={"p-grid p-mt-1"}>
                  <div className={"p-col-12"}>
                    <h3>
                      <i className={"pi pi-map-marker"}></i> Meghívott vállalkozás
                    </h3>
                  </div>
                </div>
                <div className={"p-grid"}>
                  <div className={"p-col-12 p-md-6 p-lg-6"}>
                    <Field name="name" render={({input, meta}) => (
                      <div className="p-field p-fluid">
                        <label htmlFor="name"
                               className={classNames({'p-error': isFormFieldValid(meta)})}>Meghívott vállalkozás
                          neve</label>
                        <span className={"p-d-block"}>
                              <InputText id="name" {...input}
                                         onChange={(e) => {
                                           setFormData({
                                             ...formData,
                                             name: e.target.value
                                           })
                                         }}
                                         value={formData.name}
                                         className={classNames({'p-invalid': isFormFieldValid(meta)})}/>
                            </span>
                        {getFormErrorMessage(meta)}
                      </div>
                    )}/>
                  </div>
                  <div className={"p-col-12 p-md-6 p-lg-6"}>
                    <Field name="vatNumber" render={({input, meta}) => (
                      <div className="p-field p-fluid">
                        <label htmlFor="vatNumber"
                               className={classNames({'p-error': isFormFieldValid(meta)})}>Meghívott vállalkozás
                          adószáma</label>
                        <span className={"p-d-block"}>
                              <InputText id="vatNumber" {...input}
                                         onChange={(e) => {
                                           setFormData({
                                             ...formData,
                                             vatNumber: e.target.value
                                           })
                                         }}
                                         value={formData.vatNumber}
                                         className={classNames({'p-invalid': isFormFieldValid(meta)})}/>
                            </span>
                        {getFormErrorMessage(meta)}
                      </div>
                    )}/>
                  </div>
                  <div className={"p-col-12 p-md-4 p-lg-4"}>
                    <Field name="contactLastName" render={({input, meta}) => (
                      <div className="p-field p-fluid">
                        <label htmlFor="contactLastName"
                               className={classNames({'p-error': isFormFieldValid(meta)})}>Kapcsolatartó
                          vezetéknév</label>
                        <span className={"p-d-block"}>
                              <InputText id="contactLastName" {...input}
                                         onChange={(e) => {
                                           setFormData({
                                             ...formData,
                                             contactLastName: e.target.value
                                           })
                                         }}
                                         value={formData.contactLastName}
                                         className={classNames({'p-invalid': isFormFieldValid(meta)})}/>
                            </span>
                        {getFormErrorMessage(meta)}
                      </div>
                    )}/>
                  </div>
                  <div className={"p-col-12 p-md-4 p-lg-4"}>
                    <Field name="contactFirstName" render={({input, meta}) => (
                      <div className="p-field p-fluid">
                        <label htmlFor="contactFirstName"
                               className={classNames({'p-error': isFormFieldValid(meta)})}>Kapcsolatartó
                          keresztnév</label>
                        <span className={"p-d-block"}>
                              <InputText id="contactFirstName" {...input}
                                         onChange={(e) => {
                                           setFormData({
                                             ...formData,
                                             contactFirstName: e.target.value
                                           })
                                         }}
                                         value={formData.contactFirstName}
                                         className={classNames({'p-invalid': isFormFieldValid(meta)})}/>
                            </span>
                        {getFormErrorMessage(meta)}
                      </div>
                    )}/>
                  </div>
                  <div className={"p-col-12 p-md-4 p-lg-4"}>
                    <Field name="email" render={({input, meta}) => (
                      <div className="p-field p-fluid">
                        <label htmlFor="email"
                               className={classNames({'p-error': isFormFieldValid(meta)})}>Kapcsolatartó email</label>
                        <span className={"p-d-block"}>
                              <InputText id="email" {...input}
                                         onChange={(e) => {
                                           setFormData({
                                             ...formData,
                                             email: e.target.value
                                           })
                                         }}
                                         value={formData.email}
                                         className={classNames({'p-invalid': isFormFieldValid(meta)})}/>
                            </span>
                        {getFormErrorMessage(meta)}
                      </div>
                    )}/>
                  </div>
                </div>
                <div className={"p-grid p-mt-3"}>
                  <div className={"p-col-12 p-lg-6 p-xl-6"}>
                  </div>
                  <div className={"p-col-12 p-lg-6 p-xl-6 p-text-right"}>
                    <Button type="submit" label="Meghívó küldése" icon="pi pi-check" loading={loading}/>
                  </div>
                </div>
              </form>
            )}/>
    </Panel>
  )
}
export default withTranslation('common')(EntrylocationSubcontractorInviteComponent);
