import React, {useState, useEffect, useRef} from 'react';
import {withTranslation} from 'react-i18next';

import {Form, Field} from 'react-final-form';
import {classNames} from 'primereact/utils';
import {InputText} from 'primereact/inputtext';
import {Password} from 'primereact/password';
import {Link} from "react-router-dom";
import {Button} from 'primereact/button';
import {Panel} from "primereact/panel";

import UserLoginService from '../services/user/UserLoginService';
import NetworkListService from "../services/network/NetworkListService";
import ModulesListService from "../services/modules/ModulesListService";
import {Message} from "primereact/message";
import NetworkSubscriptionMessageService from "../services/network/NetworkSubscriptionMessageService";

const LoginPage = () => {

  const [formData, setFormData] = useState({});
  const [loading, setLoading] = useState(false);

  const validate = (data) => {
    let errors = {};
    // if (!data.username) {
    //   errors.username = 'Az email mező nem lehet üres.';
    // } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(data.username)) {
    //   errors.username = 'Mindenképpen email formátumra van szükség! Pl. example@email.com';
    // }
    // if (!data.password) {
    //   errors.password = 'A jelszó nem lehet üres';
    // }
    return errors;
  };

  const onSubmit = (data, form) => {
    setLoading(true)
    setFormData(data);
    UserLoginService(data)
      .then(response => {
        localStorage.setItem("userToken", response.data.token);
        let resBase64 = response.data.token.substring(
          response.data.token.indexOf(".") + 1,
          response.data.token.lastIndexOf(".")
        );
        let res = atob(resBase64)
        let resJson = JSON.parse(res)
        localStorage.setItem("userId", resJson.id);
        localStorage.setItem("userName", resJson.username);
        localStorage.setItem("userRealName", resJson.name);
        localStorage.setItem("roles", JSON.stringify(resJson.roles).toLowerCase())
        NetworkListService()
          .then(response => {
            if (response.data && response.data[0]) {
              window.App.setState({
                networks: response.data
              })
              localStorage.setItem('networkId', response.data[0].id)
              localStorage.setItem('networkName', response.data[0].name)
            }
            ModulesListService()
              .then(response => {
                Object.entries(response.data).map(function (item, index) {
                  localStorage.setItem('module_' + item[1].code, item[1].name);
                  if (index == response.data.length - 1) {
                    document.getElementById('body').classList.remove('outside')
                    window.App.toastShow('success', 'Sikeres belépés!');
                    //setTimeout(function () {
                    window.location.replace('/#/');
                    window.App.forceUpdate()
                    window.App.setState({loggedIn: true})
                    if (localStorage.getItem('userToken') && process.env.REACT_APP_SUBSCRIPTION_MESSAGES === "enabled") {
                      NetworkSubscriptionMessageService()
                        .then(response => {
                          window.App.setState({
                            subscriptionMessages: response.data
                          })
                        })
                        .catch(error => {
                          // window.App.toastShow('error', 'Hiba történt!', error.toString(), '', error.response);
                        })
                    }
                    //window.location.reload() // TODO
                    //}, 2000)
                  }
                })
              })
              .catch(error => {
                window.App.toastShow('error', 'Hiba történt!', error.toString(), '', error.response);
                setLoading(false)
              })
          })
          .catch(error => {
            window.App.toastShow('error', 'Hiba történt!', error.toString(), '', error.response);
            setLoading(false)
          })
      })
      .catch(function (error) {
        setFormData({
          username: data.username,
          password: ''
        })
        setTimeout(function () {
          document.getElementById('password').focus()
        }, 1500)
        window.App.toastShow('error', 'Hiba történt!', error.toString(), '', error.response);
        setLoading(false)
      })
    form.restart();
  };
  const isFormFieldValid = (meta) => !!(meta.touched && meta.error);
  const getFormErrorMessage = (meta) => {
    return isFormFieldValid(meta) && <small className="p-error">{meta.error}</small>;
  };

  useEffect(() => {
    document.title = 'Bejelentkezés' + ' - ' + process.env.REACT_APP_TITLE;
    document.getElementById('body').classList.add('outside')
    setTimeout(function () {
      document.getElementById('username').focus()
    }, 500)
  }, []);

  return (
    <div className="LoginPage p-component p-p-3">
      <img src={require(".." + process.env.REACT_APP_LOGO_FILE).default} className="p-d-block p-my-5 p-mx-auto"/>
      <Panel headerTemplate={
        <div className={"p-grid"}>
          <div className={"p-col-12 p-lg-4 p-xl-6"}>
            <h3>
              <i className={"pi pi-user"}></i> Bejelentkezés
            </h3>
          </div>
        </div>
      } className={""}>
        <Form onSubmit={onSubmit}
              className="p-d-block p-mt-5 p-mx-auto"
              initialValues={formData}
              validate={validate} render={({handleSubmit, submitting}) => (
          <form onSubmit={handleSubmit}>
            <div className={"p-grid"}>
              <div className={"p-col-12"}>
                <Field name="username" render={({input, meta}) => (
                  <div className="p-field p-fluid">
                    <label htmlFor="username"
                           className={classNames({'p-error': isFormFieldValid(meta)})}>Email</label>
                    <span className="p-input-icon-right">
                    <i className="pi pi-envelope"/>
                    <InputText id="username"
                               {...input}
                               tabIndex={1}
                               value={formData.username}
                               onChange={(e) => {
                                 setFormData({
                                   ...formData,
                                   username: e.target.value
                                 })
                               }}
                               className={classNames({'p-invalid': isFormFieldValid(meta)})}/>
                  </span>
                    {getFormErrorMessage(meta)}
                  </div>
                )}/>
              </div>
              <div className={"p-col-12"}>
                <Field name="password" render={({input, meta}) => (
                  <div className="p-field p-fluid">
                    <label htmlFor="password"
                           className={classNames({'p-error': isFormFieldValid(meta)})}>Jelszó</label>
                    <Password id="password"
                              tabIndex={2}
                              {...input}
                              toggleMask
                              feedback={false}
                              value={formData.password}
                              onChange={(e) => {
                                setFormData({
                                  ...formData,
                                  password: e.target.value
                                })
                              }}
                              className={classNames({'p-invalid': isFormFieldValid(meta)})}/>
                    {getFormErrorMessage(meta)}
                  </div>
                )}/>
              </div>
            </div>
            {/*{process.env.REACT_APP_BRAND === 'trackie' &&*/}
            {/*<Message className={"w-100 p-mt-2"}*/}
            {/*         severity="warn"*/}
            {/*         text="2023. október 29. utáni első bejelentkezésedhez kérjük, hogy használd az elfelejtett jelszó funkciót!"></Message>*/}
            {/*}*/}
            <div className={"p-grid p-mt-2"}>
              <div className={"p-col-12 p-lg-6 p-xl-6"}>
                <Link to={"/password-reset"}>Elfelejtettem a jelszavam</Link>
                {process.env.REACT_APP_REGISTRATION === 'enabled' &&
                <>
                  <br/>
                  {process.env.REACT_APP_REGISTRATION_LANDING_URL && process.env.REACT_APP_REGISTRATION_LANDING_URL.toString().length > 5 ? (
                    <a href={process.env.REACT_APP_REGISTRATION_LANDING_URL}>Regisztráció</a>
                  ) : (
                    <Link to={"/registration"}>Regisztráció</Link>
                  )}
                </>
                }
              </div>
              <div className={"p-col-12 p-lg-6 p-xl-6 p-text-right"}>
                <Button type="submit" label="Belépés" className="p-button-success" icon={"pi pi-check"}
                        loading={loading} disabled={submitting}/>
              </div>
            </div>
            <div className={"p-text-center p-mt-5"}>
              <span className={"p-text-secondary"}
                    dangerouslySetInnerHTML={{__html: process.env.REACT_APP_COPYRIGHT.replace('[[YEAR]]', new Date().getFullYear())}}></span>
            </div>
          </form>
        )}/>
      </Panel>
    </div>
  )
}
export default withTranslation('common')(LoginPage);
