import React, {useState, useEffect, useRef} from 'react';
import {withTranslation} from 'react-i18next';
import {Card} from "primereact/card";
import {Button} from "primereact/button";
import {Chart} from 'primereact/chart';
import {Sidebar} from "primereact/sidebar";
import {Link} from "react-router-dom";
import {TabView, TabPanel} from 'primereact/tabview';
import {Panel} from "primereact/panel";

import OrderListComponent from "../../components/order/OrderListComponent";
import OrderShowComponent from "../../components/order/OrderShowComponent";
import ReceiptShowComponent from "../../components/receipt/ReceiptShowComponent";
import ReceiptFormComponent from "../../components/receipt/ReceiptFormComponent";
import ReceiptSendComponent from "../../components/receipt/ReceiptSendComponent";
import {Dialog} from "primereact/dialog";
import UnasListComponent from "../../components/unas/UnasListComponent";
import UnasFormComponent from "../../components/unas/UnasFormComponent";

export const OrderDashboardPage = () => {

  const [showHelpBottom, setShowHelpBottom] = useState(true);

  useEffect(() => {
    window.App.pageHeight()
    document.title = 'Megrendelések' + ' - ' + process.env.REACT_APP_TITLE;
    setTimeout(function () {
      if (document.getElementsByClassName('activeMenu')[0]) {
        document.getElementsByClassName('activeMenu')[0].classList.remove("activeMenu")
      }
      if (document.getElementsByClassName('order')[0]) {
        document.getElementsByClassName('order')[0].classList.add("activeMenu")
      }
    }, 1000)
    if (
      document.getElementById('tabScroll')
      && document.getElementById('tabScroll').children
      && document.getElementById('tabScroll').children[0]
      && document.getElementById('tabScroll').children[0].querySelector("li:not(.p-disabled)").children[0]
    ) {
      document.getElementById('tabScroll').children[0].querySelector("li:not(.p-disabled)").children[0].click()
    }
  }, []);

  return (
    <div className="ModuleDashboardPage DashboardPage p-component p-p-3 ">
      {localStorage.getItem('module_order') ? (
        <>
          <div className={"p-grid p-d-flex p-flex-wrap"}>
            <div className={"p-col-12 p-lg-8 p-xl-8 p-p-0 left p-position-relative"}>
              <TabView renderActiveOnly="true" className={"nav"} scrollable={window.App.state.tabScroll} id="tabScroll">
                {localStorage.getItem('roles').toString().includes('role_order_read') ? (
                  <TabPanel header="Megrendelések">
                    <div className={"p-p-5"}>
                      <OrderListComponent view="simple"/>
                    </div>
                  </TabPanel>
                ) : (
                  <TabPanel disabled></TabPanel>
                )}
                {localStorage.getItem('roles').toString().includes('role_unas_read') ? (
                  <TabPanel header="UNAS webáruházak">
                    <div className={"p-p-5"}>
                      <UnasListComponent/>
                    </div>
                  </TabPanel>
                ) : (
                  <TabPanel disabled/>
                )}
              </TabView>
            </div>
            <div className={"p-col-12 p-lg-4 p-xl-4 p-d-flex p-flex-wrap right p-p-5 p-text-light"}>
              <div className={"p-p-5 w-100"}>
                <h2 className={"p-mt-0"}>Műveletek</h2>
                <div className={"p-grid"}>
                  {localStorage.getItem('roles').toString().includes('role_order_write') &&
                  <div className={"p-col-12 p-lg-6 p-xl-6 p-d-flex"}>
                    <a className={"w-100 disabled"}
                      // onClick={() => window.App.setState({sidebarOrderorderCreate: true})}
                      onClick={() => {
                        alert('Megrendelés rögzítése csak gép-gép kapcsolat szinkronizációval lehetséges.')
                      }}
                    >
                      <Card className={"p-text-center w-100 shadow-none"}>
                        <i className="pi pi-plus-circle bg-white p-d-block p-mb-2"
                           style={{'fontSize': '2em'}}></i>
                        Új megrendelés
                      </Card>
                    </a>
                  </div>
                  }
                  {localStorage.getItem('roles').toString().includes('role_unas_write') &&
                  <div className={"p-col-12 p-lg-6 p-xl-6 p-d-flex "}>
                    <a onClick={() => window.App.setState({sidebarUnasCreate: true})} className={"w-100"}>
                      <Card className={"p-text-center w-100 shadow-none"}>
                        <i className="pi pi-plus-circle bg-white p-d-block p-mb-2"
                           style={{'fontSize': '2em'}}></i>
                        Új UNAS kapcsolat
                      </Card>
                    </a>
                  </div>
                  }
                </div>
                <div className={"p-text-center"}>
                  {process.env.REACT_APP_BRAND === 'trackie' &&
                  <>
                    <h3 className={"p-text-center p-text-normal"}>Gyorsmenü</h3>
                    {process.env.REACT_APP_BRAND === 'trackie' &&
                    <>
                      <Button className={"p-button-outlined p-button-sm p-ml-2 p-mt-2 p-text-light"}
                              icon="pi pi-question-circle"
                              label={"Segítség"}
                              onClick={() => {
                                setShowHelpBottom(true)
                                localStorage.removeItem('helpBottom')
                                window.App.forceUpdate()
                              }}
                      />
                    </>
                    }
                  </>
                  }
                </div>
              </div>
              <div className={"p-text-center p-as-end w-100"}>
                <span className={"p-text-light"}
                      dangerouslySetInnerHTML={{__html: process.env.REACT_APP_COPYRIGHT.replace('[[YEAR]]', new Date().getFullYear())}}></span>
              </div>
            </div>
          </div>
          <Sidebar position="left" visible={window.App.state.sidebarOrderShow} closeOnEscape={true}
                   style={{width: '98%'}}
                   onHide={() => window.App.setState({sidebarOrderShow: false})}>
            <OrderShowComponent initialValues={window.App.state.props}/>
          </Sidebar>
          <Sidebar position="left" visible={window.App.state.sidebarReceiptShow} closeOnEscape={true}
                   style={{width: '950px'}}
                   onHide={() => window.App.setState({sidebarReceiptShow: false})}>
            <ReceiptShowComponent initialValues={window.App.state.props}/>
          </Sidebar>
          <Sidebar position="left" visible={window.App.state.sidebarReceiptUpdate} closeOnEscape={true}
                   style={{width: '98%'}}
                   onHide={() => window.App.setState({
                     rerenderReceiptList: true,
                     sidebarReceiptUpdate: false,
                     newPartnerId: null,
                     newPartnerName: null
                   })}>
            <ReceiptFormComponent method="put" initialValues={window.App.state.props}/>
          </Sidebar>
          <Sidebar position="left" visible={window.App.state.sidebarUnasUpdate} closeOnEscape={true}
                   style={{width: '780px'}}
                   onHide={() => {
                     window.App.setState({sidebarUnasUpdate: false})
                   }}>
            <h2 className={"text-primary"}>UNAS kapcsolat modosítása</h2>
            <UnasFormComponent initialValues={window.App.state.props} method="put"/>
          </Sidebar>
          <Sidebar position="left" visible={window.App.state.sidebarUnasCreate} closeOnEscape={true}
                   style={{width: '780px'}}
                   onHide={() => {
                     window.App.setState({sidebarUnasCreate: false})
                   }}>
            <h2 className={"text-primary"}>Új UNAS kapcsolat</h2>
            <UnasFormComponent method="post"/>
          </Sidebar>
          {process.env.REACT_APP_BRAND === 'trackie' && (localStorage.getItem('helpBottom') === 'true' || !localStorage.getItem('helpBottom')) &&
          <Sidebar visible={showHelpBottom} position="bottom" modal={false} onHide={() => setShowHelpBottom(false)}
                   id={"helpBottom"}>
            <div className={"p-grid"}>
              <div className={"p-col-12 p-lg-2 p-d-none p-d-lg-block p-col-align-center"}>
                <img src={require('../../assets/img/undraw_air_support_re_nybl.svg').default}
                     className="p-d-block p-mx-auto w-50"/>
              </div>
              <div className={"p-col p-col-align-center"}>
                <h3 className={"p-mt-0"}>
                  <i className={"pi pi-question-circle"}></i> Gyors segítség a megrendelések modul használatához
                </h3>
                <p>
                  <strong className={"p-text-uppercase p-d-block text-dark"}>UNAS webáruház megrendelések</strong>
                  A számlázható státuszú UNAS megrendeléseket negyed óránként szinkronizáljuk a webáruházból. A
                  megrenelés részleteit
                  a <i className={"pi pi-eye"}></i> inkonra kattintva éred el. Ugyanitt megtalálod a megrendeléshez
                  kiállított bizonylatok listáját is. Automatikus UNAS beállítás esetén a számlák létrehozása és
                  kiállítása az UNAS webáruház felületén a megrendelés Számlázható státusza alapján automatiksan
                  történik.
                  A <i className={"pi pi-file"}></i> ikon legördülő menüjéből kiválaszthatod, hogy melyik bizonylat
                  tömbből szeretnél új bizonylatot létrehozni a megrendelés alapján, legyen az számla, díjbekérő vagy
                  akár szállítólevél. A létrejövő új bizonylatot szabadon
                  szerkesztheted. A létrejövő új bizonylat mintaként jön létre, véglegesítéséhez és kézbesítéséhez a
                  bizonylat kiállítására kell kattintanod, amennyiben befejezted a szerkesztését.
                </p>
                <Button icon="pi pi-times" className="p-button p-button-sm p-mt-2 p-mr-2"
                        label={"Ne jelenjen meg többet ez az üzenet"}
                        onClick={() => {
                          if (window.confirm('Bezárást követően nem jelenik meg többé ez az üzenet!')) {
                            localStorage.setItem('helpBottom', false);
                            window.App.forceUpdate()
                          }
                        }}
                />
                <Button icon="pi pi-download" className="p-button-secondary p-button-sm p-mt-2 disabled p-mr-2"
                        label={"Felhasználói kézikönyv letöltése"}
                />
                <a href={"mailto:hello@trackie.io"} className={"p-button p-button-outlined"} style={{border: 0}}>További
                  segítségre van szükséged? hello@trackie.io</a>
              </div>
            </div>
          </Sidebar>
          }
          <Dialog visible={window.App.state.dialogReceiptSend}
                  style={{width: '450px'}}
                  header="Számla kézbesítése emailben"
                  modal
                  onHide={() => {
                    window.App.setState({dialogReceiptSend: false})
                  }}>
            <ReceiptSendComponent/>
          </Dialog>
        </>
      ) : (
        <p className={"p-text-center p-my-5 p-py-5"}>
          <i className={"pi pi-exclamation-circle p-d-block p-mb-5"} style={{'fontSize': '5em'}}></i>
          <span className={"p-text-bold p-d-block "}>Ehhez a modulhoz sajnos nem rendelkezel hozzáféréssel.</span>
          Amennyiben további információra van szükséged írj emailt a {process.env.REACT_APP_DEVOPS_CONTACT} címre.
          {process.env.REACT_APP_SUBSCRIPTIONS === 'enabled' &&
          <span className={"p-text-bold p-d-block p-mt-5"}>
                       <a className={"p-button p-button-warning"}
                          onClick={() => {
                            window.location.replace('/#/subscriptions');
                            window.App.forceUpdate()
                          }}
                       >
                         <i className={"pi pi-building"}></i>&nbsp;Előfizetői fiók áttekintése
                       </a>
                   </span>
          }
        </p>
      )}
    </div>
  )
}
export default withTranslation('common')(OrderDashboardPage);
